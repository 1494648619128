import React from "react";
import PictureWraper from "../SubComponents/PictureWraper";

function ProductContent({ product }) {
  return (
    <div className="product-content">
      <PictureWraper imgSrc={product.images} />
      <section>
        <ul>
          <li>{product.Item_Number}</li>
          <li>
            <small>{product.Description}</small>
          </li>
        </ul>

        <div className="price">
          <span>
            {product.quantity} x ${product.Price}
          </span>
        </div>
      </section>
    </div>
  );
}

export default ProductContent;
