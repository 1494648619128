import React, { useEffect } from "react";
import { getCategories } from "../../getData/Elastic_search";
import Images from "../../config/Images";

function About() {
  return (
    <main className="about-page">
      {/* <CategoriesList /> */}
      <div className="page-content">
        <div className="img-wrapper">
          <img src={Images.logo2} />
        </div>

        <div className="aboutUs">
          <h1>About Us:</h1>
          <p>We Are Phoenix, A Wholesaler Of Candy, Tobacco, Novelty,</p>
          <p>And Other Items Catering To Your Store Needs. </p>
          <p>
            We Strive To Provide You With Quality Service And The Best Prices.
          </p>
        </div>
      </div>
    </main>
  );
}

export default About;
