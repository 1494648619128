import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import path from "../../config/path";
import { Link, useNavigate } from "react-router-dom";
import Category from "../CategoriesList/Category";
import { useStateValue } from "../../config/StateProvider";
import { useState } from "react";
function LeftShowCategory({ show, categoryList, HideCategory, HideNavMenu }) {
  const navigate = useNavigate();
  const [{ showCategories }] = useStateValue();
  const [subList, setSubList] = useState([]);
  return (
    <section className="category-list" style={{ left: showCategories }}>
      <div className="container">
        <div className="categoriesTop">
          {/* <FontAwesomeIcon icon={faBars} /> */}
          <h1>Categories</h1>
          <CloseIcon
            className="close"
            onClick={() => HideCategory()}
          ></CloseIcon>
        </div>

        <ul>
          <li>
            <Link to={path.productList} onClick={HideNavMenu}>
              All
            </Link>
          </li>
          {Object.entries(categoryList).map(([key, value]) => (
            <Category
              parentCategory={key}
              subCategory={value}
              key={key}
              HideNavMenu={HideNavMenu}
            />
          ))}
        </ul>
      </div>
    </section>
  );
}

export default LeftShowCategory;
