import React from "react";
import { Link } from "react-router-dom";
import path from "../../config/path";
import { checkPricingCode } from "../../Funcs";
import PictureWraper from "../SubComponents/PictureWraper";
import RatingStars from "../SubComponents/RatingStars";
function index({ product, children }) {
  return (
    <div className="product-info">
      <Link to={path.detail + "/" + product.Item_Number} className="content">
        <PictureWraper imgSrc={product.images} />
        <section>
          <h3>{product.Description}</h3>
          <RatingStars raingValue={product.countRequestedProducts} />
          <div className="price">
            <span>${product.Price}</span>
          </div>
        </section>
      </Link>
      {children}
    </div>
  );
}

export default index;
