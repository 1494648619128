import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import path from "../../config/path";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Images from "../../config/Images";
import SearchIcon from "../../assets/svg/SearchIcon";

function SearchForm() {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const ProductSearch = (e) => {
    e.preventDefault();
    if (searchValue.trim().length != 0) {
      navigate(path.productList + "/type/search/" + searchValue.trim());
    } else {
      navigate(path.productList);
    }
  };
  return (
    <form className="search-container" onSubmit={ProductSearch}>
      <div onClick={ProductSearch} className="search-icon">
        {/* <FontAwesomeIcon icon={faSearch} /> */}
        {/* <img src={Images.searchIcon} alt={Images.searchIcon} /> */}
        <SearchIcon />
      </div>
      <input
        type="text"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        placeholder="Search"
      />
    </form>
  );
}

export default SearchForm;
