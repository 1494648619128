import React from "react";
import { Link } from "react-router-dom";
import path from "../../config/path";
import Images from "../../config/Images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhoneAlt,
  faMapMarkerAlt,
  faFax,
} from "@fortawesome/free-solid-svg-icons";
import { faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import FacebookIcon from "@material-ui/icons/Facebook";

export default function footer() {
  return (
    <footer>
      <section className="bottom-section">
        <section className="left-section">
          <img src={Images.logoWhite} />
          <div className="small-description"></div>
        </section>

        <section className="right-section">
          <ul>
            <li>
              <h1>Links</h1>
            </li>
            <li>
              <Link to={path.home}>Home</Link>
            </li>
            <li>
              <Link to={path.about}>About</Link>
            </li>
            <li>
              <Link to={path.contact}>Contact Us</Link>
            </li>
          </ul>

          <ul className="contact">
            <li>
              <h1>Contact Information</h1>
            </li>
            {/* <li>
              <a>
                <FontAwesomeIcon icon={faMapMarkerAlt} />
                6935 Chase Dearborn MI 48126
              </a>
            </li> */}
            {/* <li>
              <a>
                <FontAwesomeIcon icon={faFax} />
              </a>
            </li> */}
            <li>
              <a href="tel:(313) 914-2720">
                <FontAwesomeIcon icon={faPhoneAlt} />
                (313)914-2720
              </a>
            </li>
            <li>
              <a href="mailto:Phoenixgwholesale@gmail.com">
                <FontAwesomeIcon icon={faEnvelope} />
                Phoenixgwholesale@gmail.com
              </a>
            </li>
          </ul>
          <div className="follow">
            <h1>Follow Us</h1>
            <ul>
              <li>
                <a href="tel:">
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
              </li>
              <li>
                <a href="tel:">
                  <FacebookIcon />
                </a>
              </li>
              <li>
                <a href="tel:">
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
              </li>
            </ul>

            <div className="designedBy">
              <a href="https://ideasadds.ideas-internal.com/">
                <ul>
                  <li> Designed & Developed</li>
                  <li>by Ideas Development Solutions</li>
                </ul>
              </a>
            </div>
          </div>
        </section>
      </section>
    </footer>
  );
}
