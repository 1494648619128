import React, { useEffect } from "react";
import SubTotal from "./SubTotal";
import { useStateValue } from "../../config/StateProvider";
import ProductCart from "./ProductCart";
import SpinnerRotate from "../SubComponents/SpinnerRotate";
import CategoriesList from "../CategoriesList/CategoriesList";
import SchedualSubTotal from "../Scheduals/SchedualSubTotal";
import PosUserInfo from "../Scheduals/PosUserInfo";

function Cart() {
  const [{ basket, user }] = useStateValue();
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className="cart-page">
      <CategoriesList />
      {user ? (
        <>
          <section className="cart-content">
            {Object.values(basket).length > 0 ? (
              <div
                className={
                  user.switched_user?.hasSchedual
                    ? "items-cart schedualed-order"
                    : "items-cart"
                }
              >
                {user.switched_user?.hasSchedual ? (
                  <>
                    <h2>schedule order</h2>
                    <PosUserInfo
                      CUSTOMER_NUMBER={
                        user.switched_user.hasSchedual.posuser.Customer_Number
                      }
                      TAXID={user.switched_user.hasSchedual.posuser.TAXID}
                      COMPANY={user.switched_user.hasSchedual.posuser.Company}
                      PRINCING_CODE={
                        user.switched_user.hasSchedual.posuser.PricingCode
                      }
                      // status={user.switched_user.hasSchedual.status}
                    />
                  </>
                ) : null}
                {Object.values(basket).map((item, index) => {
                  return item != null ? (
                    <ProductCart item={item} index={index} key={index} />
                  ) : (
                    <></>
                  );
                })}
              </div>
            ) : (
              <></>
            )}
            <div className="subTotal-gap">
              {user.switched_user?.hasSchedual ? (
                <SchedualSubTotal />
              ) : (
                <SubTotal />
              )}
            </div>
          </section>
        </>
      ) : (
        <SpinnerRotate />
      )}
    </div>
  );
}
export default Cart;
//     <input value={item.quantity} onChange={(e) => handeQuantityInput(e.target.value)} type="number" />
