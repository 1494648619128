import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
// import "../css/eventHandler.css";

function EventHandler(
  when_yes,
  dialog_title,
  dialog_message,
  label_yes,
  label_no
) {
  return confirmAlert({
    title: dialog_title,
    message: dialog_message,
    closeOnEscape: true,
    closeOnClickOutside: true,
    buttons: [
      {
        className: "when_yes",
        label: label_yes,
        onClick: () => when_yes(),
      },
      {
        className: "when_cancel",
        label: "No",
        onClick: () => {
          return null;
        },
      },
    ],
  });
  // const eventYesNo = window.confirm(dialog_title, dialog_message);
  // if (eventYesNo) {
  //   when_yes();
  // }
}

export default EventHandler;
