import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useStateValue } from "../../config/StateProvider";
import { getById } from "../../getData/Elastic_search";
import CategoriesList from "../CategoriesList/CategoriesList";
import { checkPricingCode, optimizeProductPriceObject } from "../../Funcs";
import SimilarProducts from "./SimilarProducts";
import QtyInput from "../SubComponents/QtyInput";
import SpinnerRotate from "../SubComponents/SpinnerRotate";
import RatingStars from "../SubComponents/RatingStars";
function ProductDetails() {
  const { id } = useParams();
  const [product, setProduct] = useState({});
  const [{ basket, user }, dispatch] = useStateValue();
  const [loading, setLoading] = useState(true);
  async function fetchProduct() {
    let index;
    await getById(id).then((result) => {
      // console.log("new id", id);
      if (result) {
        result.data.hits.hits.map((hit) => {
          const obj = Object.assign({}, hit._source);
          index = Object.keys(basket).findIndex(
            (Item_Number_key) => Item_Number_key === obj.Item_Number
          );
          if (index >= 0) {
            // console.log("existed", basket[obj.Item_Number]);
            // replace detail from existance in cart
            product.detail = basket[obj.Item_Number];
          } else {
            // console.log("not existed in basket", obj);
            let price = checkPricingCode(obj, user);
            obj.Price = price;
            obj.quantity = 1;
            // const { PriceA, PriceB, PriceC, PriceD, Retail, ...rest } = obj;
            // product.detail = { ...rest };
            product.detail = obj;
          }
        });
        // console.log("pp", product);
      }
    });
    if (index < 0) {
      product.detail.quantity = 1;
      // product.detail.totalPrice = product.detail.price;
    }
    // product.detail.selected = true;
    const productObj = Object.assign({}, product);
    // console.log("productObj", productObj);
    setProduct(productObj);
    setLoading(false);
  }

  const AddToBasket = () => {
    // console.log("addddd");

    if (user == null || user.status === -1) {
      alert("Login to add to your cart");
    } else {
      const obj = optimizeProductPriceObject(product.detail, user);
      // console.log("aa", obj);
      dispatch({
        type: "Add_To_Basket",
        item: obj,
        event: "addProduct",
        useruid: user.uid,
      });
    }
  };

  const handeQuantityInput = (qty) => {
    // console.log("qty", qty);
    product.detail.quantity = qty;
    const new_request = Object.assign({}, product);
    setProduct(new_request);
  };
  const handleIncrementQty = () => {
    // product.detail.quantity = product.detail.quantity + 1;
    const newProduct = Object.assign({}, product);
    newProduct.detail.quantity = product.detail.quantity + 1;
    setProduct(newProduct);
  };
  const handleDecrementQty = () => {
    if (
      product.detail.quantity == "" ||
      product.detail.quantity == 1 ||
      product.detail.quantity == 0
    )
      return;
    const newProduct = Object.assign({}, product);
    newProduct.detail.quantity = product.detail.quantity - 1;
    setProduct(newProduct);
  };
  useEffect(() => {
    setLoading(true);
    fetchProduct();
    window.scrollTo(0, 0);
    // return {};
  }, [id]);

  // return <>{user != null ? <div>dsadas</div> : <Redirect to={login} />}</>;
  return (
    <main className="product-detail">
      <CategoriesList />
      <section className="page-detail">
        {product.detail ? (
          <SimilarProducts parentCategory={product.detail.Category} />
        ) : (
          <></>
        )}
        {!loading ? (
          <>
            {product.detail ? (
              <section className="page-content">
                <div className="image-wrapper">
                  <img
                    src={product.detail.images}
                    alt={product.detail.images}
                  />
                </div>
                <div className="details">
                  <h2>{product.detail.Description}</h2>
                  <RatingStars
                    raingValue={product.detail.countRequestedProducts}
                  />
                  <div className="price">
                    <h3>Price: </h3>

                    <span>${product.detail.Price}</span>
                  </div>
                  <div className="input-container">
                    <h3>Quantity</h3>
                    <QtyInput
                      quantity={product.detail.quantity}
                      handleIncrementQty={handleIncrementQty}
                      handleDecrementQty={handleDecrementQty}
                      handeQuantityInput={handeQuantityInput}
                    />
                  </div>
                  <div className="footer-product">
                    <div className="description">
                      <div className="about-this-item">
                        {/* <h3>About this item</h3> */}
                        <div
                          onClick={() => AddToBasket()}
                          className="add-to-cart"
                        >
                          ADD TO CART
                        </div>
                      </div>

                      {/* <div>{product.detail.description}</div> */}
                    </div>
                  </div>
                </div>
              </section>
            ) : (
              <></>
            )}
          </>
        ) : (
          <SpinnerRotate />
        )}
      </section>
    </main>
  );
}

export default ProductDetails;
