import React from "react";
import { FaMinus, FaPlus } from "react-icons/fa";

function QtyInput({
  quantity,
  handleDecrementQty,
  handleIncrementQty,
  handeQuantityInput,
}) {
  const validateQty = (e) => {
    const newValue = e.target.value;
    const onlyNumbers = /^[0-9]+$/;
    if (onlyNumbers.test(newValue)) {
      handeQuantityInput(parseInt(newValue));
    }
    if (newValue == "") {
      handeQuantityInput(newValue);
    }
  };
  const handleOnBlur = (e) => {
    if (e.target.value == "") {
      handeQuantityInput(1);
    }
  };
  return (
    <div className="input-wraper">
      <span className="minus-wraper" onClick={handleDecrementQty}>
        <FaMinus />
      </span>

      <input
        className="products-inputs-quantity"
        type="text"
        placeholder="Qty"
        onChange={validateQty}
        value={quantity}
        // defaultValue={1}
        maxLength={3}
        onBlur={handleOnBlur}
      />
      <span className="plus-wraper" onClick={handleIncrementQty}>
        <FaPlus />
      </span>
    </div>
  );
}

export default QtyInput;
