import React, { useEffect } from "react";
import {
  fetchTotalRequestedProducts,
  getAllScheduals,
  getCategories,
  // getDiscountProducts,
  getLastUpdatedProducts,
} from "./Elastic_search";
import { useStateValue } from "../config/StateProvider";

function FetchEssentialData() {
  const [{ homeProducts, discountedHomeList }, dispatch] = useStateValue();

  async function fetchProducts() {
    await getLastUpdatedProducts(0, 16).then((result) => {
      let productsTemp = [];
      if (result) {
        result.data.hits.hits.map((hit) => {
          productsTemp = [...productsTemp, hit._source];
        });
        dispatch({
          type: "HOME_PRODUCTS",
          homeProducts: productsTemp,
        });
      }
    });

    await getCategories().then((result) => {
      const temp = [];
      if (result) {
        result.data.hits.hits.map((hit, index) => {
          const obj = Object.assign({}, hit._source);
          obj.id = index;
          temp.push(obj);
        });
        // console.log("getCategories", temp);
        dispatch({
          type: "SET_CATEGORIES",
          categoryList: temp[0].categoryList,
        });

        if (result.data.hits.hits[0]._source.shipment) {
          dispatch({
            type: "SET_SHIPPING_COST",
            shippingCost: result.data.hits.hits[0]._source.shipment,
          });
        }
      }
      // console.log("temps", temp);
    });
  }

  const getRequstedCounter = () => {
    fetchTotalRequestedProducts().then((res) => {
      // console.log("res", res);
      // res.data.hits.hits.map((item_hit,idx)=>{
      // })
    });
  };

  useEffect(() => {
    fetchProducts();
    // getRequstedCounter();
  }, []);
  return <></>;
}

export default FetchEssentialData;
