import { doc, setDoc, updateDoc } from "@firebase/firestore";
import { db } from "./firebaseConfig";
/*
var basketStorage = {};
basketStorage.basket = [];

if (localStorage.getItem("basket") != null) {
  basketStorage.basket = JSON.parse(localStorage.getItem("basket")).basket;
}*/
export const initialState = {
  basket: {},
  productList: [],
  productToAdd: [],
  user: null,
  switched_user: null,
  addAction: false,
  homeProducts: [],
  discountedHomeList: [],
  categoryList: [],
  shippingCost: 0,
  userSchedual: null,
  showCategories: "",
};

export const actionTypes = {
  Add_To_Basket: "Add_To_Basket",
  SET_BASKET: "SET_BASKET",
  Remove_From_Basket: "Remove_From_Basket",
  SET_USER: "SET_USER",
  SWITCH_USER: "SWITCH_USER",
  EMPTY_BASKET: "EMPTY_BASKET",
  SET_LOAD: "SET_LOAD",
  SET_PRODUCT: "SET_PRODUCT",
  BASKET_UPDATE: "BASKET_UPDATE",
  PRODUCTS_TO_ADD: "PRODUCTS_TO_ADD",
  REMOVE_PRODUCTS_TO_ADD: "REMOVE_PRODUCTS_TO_ADD",
  THE_ADD_ACTION: "THE_ADD_ACTION",
  HOME_PRODUCTS: "HOME_PRODUCTS",
  TOGGLE_SHOW_CATEGORIES: "TOGGLE_SHOW_CATEGORIES",
  DISCOUNTED_HOME_LIST: "DISCOUNTED_HOME_LIST",
  SET_CATEGORIES: "SET_CATEGORIES",
  SET_SHIPPING_COST: "SET_SHIPPING_COST",
  SET_USER_SCHEDUAL: "SET_USER_SCHEDUAL",
};

export const getBasketTotal = (basket) =>
  basket?.reduce((amount, item) => item.price + amount, 0);

export const setDocFireBase = (basket, useruid) => {
  // console.log("initialState", useruid);
  const userDoc = doc(db, "users", useruid);
  // myUser.basket = basket;

  updateDoc(userDoc, {
    basket: basket,
  });
};
export const convertMapToArray = (basket) => {
  let theBasket = [];

  for (var key of Object.keys(basket)) {
    theBasket.push(basket[key]);
  }
  return theBasket;
};
const checkExistance = (basket, product) =>
  Object.keys(basket).find(
    (Item_Number_key) => Item_Number_key === product.Item_Number
  );

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.Add_To_Basket:
      // console.log("action", action);
      const itemKeyRef = action.item.Item_Number;

      const newBasket = Object.assign({}, state.basket);
      let newItem = Object.assign({}, action.item);

      newBasket[itemKeyRef] = newItem;

      if (action.event == "addProduct") {
        setDocFireBase(newBasket, action.useruid);
      }

      return {
        ...state,
        basket: newBasket,
      };

    case actionTypes.THE_ADD_ACTION:
      return {
        ...state,
        addAction: true,
      };
    case actionTypes.PRODUCTS_TO_ADD:
      return {
        ...state,
        productToAdd: [...state.productToAdd, action.item],
      };
    case actionTypes.REMOVE_PRODUCTS_TO_ADD:
      // const index1 = state.productToAdd.findIndex(
      //   (basketItem) => basketItem.Item_Number === action.item.Item_Number
      // );
      // let newBasket1 = [...state.productToAdd];
      const newBasket1 = Object.assign({}, state.basket);
      delete newBasket1[action.item.Item_Number];
      if (action.event == "REMOVE_PRODUCTS_TO_ADD") {
        setDocFireBase(newBasket1, action.useruid);
      }
      // if (index1 >= 0) {
      //   newBasket1.splice(index1, 1);
      //   if (action.event == "REMOVE_PRODUCTS_TO_ADD") {
      //     setDocFireBase(newBasket1);
      //   }
      // } else {
      //   console.warn(
      //     `Cant remove product (id:${action.id}) as its not in the basket`
      //   );
      // }
      return {
        ...state,
        productToAdd: newBasket1,
      };
    case actionTypes.Remove_From_Basket:
      const newBasket2 = Object.assign({}, state.basket);
      delete newBasket2[action.Item_Number];
      // console.log("newBasket2", newBasket2);
      setDocFireBase(newBasket2, action.useruid);

      return {
        ...state,
        basket: newBasket2,
      };
    case actionTypes.SET_USER:
      if (action.event === "to_cloud") {
        // console.log("to_cloud");
        updateDoc(doc(db, "users", action.user.uid), {
          switched_user: action.user.switched_user,
        });
      }

      return {
        ...state,
        user: action.user,
      };
    case actionTypes.EMPTY_BASKET:
      //   basketStorage.basket = [];
      //   localStorage.setItem("basket", JSON.stringify(basketStorage));
      setDocFireBase({}, action.useruid);
      return {
        ...state,
        basket: {},
      };

    case actionTypes.SET_PRODUCT:
      return {
        ...state,
        productList: action.item,
      };

    case actionTypes.SET_BASKET:
      return {
        ...state,
        basket: action.basket,
      };
    case actionTypes.BASKET_UPDATE:
      //    basketStorage.basket = [...action.basket];
      //   localStorage.setItem("basket", JSON.stringify(basketStorage));
      let basketObj = {};
      if (action.setDoc != undefined && action.setDoc) {
        setDocFireBase(action.basket, action.useruid);
      }

      if (action.fromElastic != undefined) {
        if (action.fromElastic) {
          basketObj = action.basket;
          return {
            ...state,
            basket: basketObj,
          };
        }
      }
      // console.log("action ", action.basket);
      return {
        ...state,
        basket: action.basket,
      };
    case actionTypes.HOME_PRODUCTS:
      return {
        ...state,
        homeProducts: action.homeProducts,
      };
    case actionTypes.DISCOUNTED_HOME_LIST:
      return {
        ...state,
        discountedHomeList: action.discountedHomeList,
      };
    case actionTypes.SET_CATEGORIES:
      return {
        ...state,
        categoryList: action.categoryList,
      };
    case actionTypes.SET_SHIPPING_COST:
      return {
        ...state,
        shippingCost: action.shippingCost,
      };
    case actionTypes.SWITCH_USER:
      return {
        ...state,
        switched_user: action.switched_user,
      };
    case actionTypes.SET_USER_SCHEDUAL:
      return {
        ...state,
        userSchedual: action.userSchedual,
      };
    case actionTypes.TOGGLE_SHOW_CATEGORIES:
      return {
        ...state,
        showCategories: action.showCategories,
      };
    default:
      return state;
  }
};

export default reducer;
