import { db, storage } from "../config/firebaseConfig";
import {
  collection,
  addDoc,
  doc,
  setDoc,
  updateDoc,
  increment,
  serverTimestamp,
  runTransaction,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  ProductConstruct,
  checkingProductBeforeSync,
} from "../Funcs/Structures";
// import imageCompression from "browser-image-compression";
import Compress from "browser-image-compression";
export const sendEmailCashonDelivery = async (
  selectedItems,
  email,
  total,
  user,
  radioValue,
  userRequest
) => {
  let shippment = "";
  if (radioValue === "pickup") {
    shippment = "On Pickup";
  } else {
    shippment = "On Delivery";
  }
  let subjectEmail = `New Order for ` + email;

  let bodyEmail =
    "<div style = 'padding-bottom: 100px;'><img src ='https://firebasestorage.googleapis.com/v0/b/pheonix-wholesale.appspot.com/o/logo.png?alt=media&token=74ccd083-81b4-43ba-ae20-d8ce2176b149' /></div><h1>Payment: <strong> Cash</strong></h1><h2>Shipment: " +
    shippment +
    "</h2><h2>Name: " +
    userRequest.name +
    "</h2><h2>Email: " +
    email +
    "</h2>";
  bodyEmail +=
    "<table border=1><tr><th>Image</th><th>Product Name</th><th>Quantity</th><th>Price</th></tr>";
  Object.values(selectedItems).map((product) => {
    bodyEmail += "<tr>";
    bodyEmail +=
      "<td><img style='width: 150px;' src = " + product.images + "/></td>";
    bodyEmail +=
      "<td style='text-align: center; min-width: 70px;'>" +
      product.Description +
      "</td>";
    bodyEmail +=
      "<td style='text-align: center; min-width: 70px;'>" +
      product.quantity +
      "</td>";
    bodyEmail +=
      "<td style='text-align: center; min-width: 70px;'>" +
      product.Price +
      " $</td>";
    bodyEmail += "</tr>";
  });
  bodyEmail += "</table>";
  bodyEmail += "<h3> total cost: " + total.toFixed(2) + "$</h3>";
  const message = {
    to: "kassemmoghraby1992@outlook.com",
    message: {
      subject: subjectEmail,
      html: bodyEmail,
    },
  };
  await addDoc(collection(db, "mail"), message);
};

export const update_Stock_inventory = (products) => {
  // const unique_products = [...new Set(products.map((item) => item.productId))];

  products.forEach((item) => {
    // let items = products.filter((element) => element.productId === id);
    // let amount = items.reduce(
    //   (accumulator, current) => accumulator + current.quantity,
    //   0
    // );
    const docRef_product = doc(db, "products", item.productsId);
    updateDoc(docRef_product, {
      inStock: increment(-item.quantity),
      countRequestedProducts: increment(item.quantity),
    });
  });
};

export const placeOrder = (requestid, orderRequest) => {
  return setDoc(doc(db, "requests", requestid), orderRequest);
};

export const updateSchuale = (hasSchedual, basket, total_cost, radioValue) => {
  // console.log("hasSchedual", hasSchedual);
  //change : updatedAt, total, status :1, shipping : radioValue,products:basket, placedByuid,
  const new_chedual_obj = Object.assign({}, hasSchedual);

  const doc_admin = doc(
    db,
    "users",
    new_chedual_obj.placedByuid,
    "schedualedorders",
    new_chedual_obj.scheduleuserid
  );
  updateDoc(doc_admin, {
    products: basket,
    total: total_cost,
    shippingType: radioValue,
    status: 1,
    updatedAt: serverTimestamp(),
  });
};
const addProduct = async (objToAdd) => {
  const docRef = doc(db, "products", `${objToAdd.Item_Number}`);
  const imgUrl = process.env.REACT_APP_NO_IMAGE_URL_TEST;
  const obj = Object.assign({}, objToAdd);
  // additional fields
  obj.Description = objToAdd.Description.toLowerCase();
  obj.timestamp = serverTimestamp();
  obj.images = imgUrl;
  obj.countRequestedProducts = "";
  obj.withPicture = false;

  return setDoc(docRef, obj);
};
export const syncProduct = async (objToAdd) => {
  // console.log(objToAdd);
  // Create a reference to the SF doc.
  // const productObject = new ProductConstruct(objToAdd);
  // console.log("productObject", checkingProductBeforeSync(objToAdd));
  const obj = checkingProductBeforeSync(objToAdd);

  const sfDocRef = doc(db, "products", `${obj.Item_Number}`);
  // console.log("test", obj);
  try {
    await runTransaction(db, async (transaction) => {
      const sfDoc = await transaction.get(sfDocRef);
      if (!sfDoc.exists()) {
        // throw "Document does not exist!";

        addProduct(obj);
      } else {
        const newPop = sfDoc.data();
        // console.log("obj", obj);
        // console.log("update", obj);
        transaction.update(sfDocRef, obj);
      }
    });
    // console.log("Population increased to ", newPopulation);
  } catch (e) {
    // This will be a "population is too big" error.
    console.error(e);
  }
};

export const database_updateImage = async (newProductObj, fileCompressed) => {
  // console.log("newProductObj", newProductObj);
  // console.log("item to upload", item);
  // const product = Object.assign({}, item);
  const imagesRef = ref(storage, `images/${newProductObj.Item_Number}`);
  const metadata = {
    contentType: "image/jpeg",
  };
  await uploadBytes(imagesRef, fileCompressed);
  await getDownloadURL(ref(storage, imagesRef)).then((url) => {
    newProductObj.images = url;
    // product.imageList = [url];
    // console.log("url", url);
    // console.log("newProductObj.Item_Number", newProductObj.Item_Number);
    const docRef = doc(db, "products", `${newProductObj.Item_Number}`);
    // setDoc(docRef, newProductObj);
    // console.log("docRef", docRef);
    updateDoc(docRef, {
      images: url,
      timestamp: serverTimestamp(),
    });
  });
};
export const updateShipment = async (shipmentCost) => {
  try {
    const docRef = doc(db, "admin", "settings");
    await updateDoc(docRef, {
      shipment: shipmentCost,
    });
  } catch (e) {}
};
export const database_updateProduct = (item) => {
  // console.log("database_updateProduct", item);
  try {
    item.updatedAt = serverTimestamp();
    const docRef = doc(db, "products", `${item.Item_Number}`);
    updateDoc(docRef, item);
  } catch (e) {
    console.log("faild to apply changes in the database", e);
    alert("faild to apply changes in the database ");
  }
};
