import Compress from "browser-image-compression";
import { auth } from "../config/firebaseConfig";
import EventHandler from "../components/SubComponents/EventHandler";
export const checkPricingCode = (item, user) => {
  // user.POS_USER.PricingCode
  // console.log("kkk", item);
  if (user) {
    if (user.switched_user) {
      if (user.switched_user.PricingCode) {
        return item[user.switched_user.PricingCode];
      } else {
        return item.Retail;
      }
    } else {
      if (user.posUserInfo) {
        if (user.posUserInfo.PricingCode) {
          return item[user.posUserInfo.PricingCode];
        } else {
          return item.Retail;
        }
      } else {
        return item.Retail;
      }
    }
  } else {
    return item.Retail;
  }
};

export const optimizeProductPriceObject = (item_details, user) => {
  let price = "";
  const obj = Object.assign({}, item_details);
  price = checkPricingCode(obj, user);
  obj.Price = price;

  const { PriceA, PriceB, PriceC, PriceD, Retail, isBestDeal, ...rest } = obj;

  return {
    Description: obj.Description,
    images: obj.images,
    Price: obj.Price,
    Item_Number: obj.Item_Number,
    Case_Pack: obj.Case_Pack,
    quantity: obj.quantity,
  };
};

const handleImageUpload = async (imageFile) => {
  try {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1024,
      useWebWorker: true,
    };
    const compressedBlob = await Compress(imageFile, options);
    // console.log("originalFile instanceof Blob", imageFile instanceof Blob); // true
    // console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
    // console.log("compressedFile", compressedBlob);
    const convertedBlobFile = new File([compressedBlob], imageFile, {
      type: imageFile.type,
      lastModified: Date.now(),
    });
    // console.log("convertedBlobFile", convertedBlobFile);
    return convertedBlobFile;
  } catch (e) {
    // console.log("faild to compress", e);
  }
};
export const compressPicture = async (e) => {
  // Get the files selected from the input tag
  // On select files always come in an array even
  // if you choose one its the first index
  // if you selected a couple then loop through the array
  const file = e.target.files[0];

  // Compression config
  const options = {
    // As the key specify the maximum size
    // Leave blank for infinity
    maxSizeMB: 1.5,
    // Use webworker for faster compression with
    // the help of threads
    useWebWorker: true,
  };

  // Initialize compression
  // First argument is the file object from the input
  // Second argument is the options object with the
  // config
  // console.log("file", file);
  await Compress(file, options)
    .then((compressedBlob) => {
      // Compressed file is of Blob type
      // You can drop off here if you want to work with a Blob file
      // console.log(compressedBlob);

      // If you want to work with the File
      // Let's convert it here, by adding a couple of attributes
      compressedBlob.lastModifiedDate = new Date();

      // Conver the blob to file
      const convertedBlobFile = new File([compressedBlob], file.name, {
        type: file.type,
        lastModified: Date.now(),
      });
      // console.log("convertedBlobFile", convertedBlobFile);
      return convertedBlobFile;
      // Here you are free to call any method you are gonna use to upload your file example uploadToCloudinaryUsingPreset(convertedBlobFile)
    })
    .catch((e) => {
      // Show the user a toast message or notification that something went wrong while compressing file
      console.log("fail to commpress img", e);
    });
};
const SignOut = () => {
  try {
    auth.signOut().then(() => {
      // navigate(path.home);
    });
  } catch (e) {}
};
export const handleExist = () => {
  EventHandler(
    () => {
      SignOut();
    },
    "Sign Out",
    "",
    "Yes"
  );
};
