import {
  faEnvelope,
  faFax,
  faMapMarkerAlt,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import CategoriesList from "../CategoriesList/CategoriesList";

// Phoenix General Wholesale
// (313) 914-2720
// https://goo.gl/maps/rv4oPzv25HjyfbmM8
// frameBorder="0"
// scrolling="no"
// marginHeight="0"
// marginWidth="0"

function Contact() {
  return (
    <main className="contact-page">
      <CategoriesList />
      <div className="page-content">
        <div className="mapouter">
          <div className="gmap_canvas">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2949.3216066456644!2d-83.23797568454444!3d42.33566617918856!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883b3543ce99d75d%3A0x1d732d19160deefc!2sPhoenix%20General%20Wholesale!5e0!3m2!1sen!2sus!4v1647625333462!5m2!1sen!2sus"
              width="800"
              height="600"
              // style="border:0;"
              allowFullScreen=""
              loading="lazy"
              frameBorder="0"
              marginHeight="0"
              marginWidth="0"
            ></iframe>
          </div>
        </div>

        <div className="contact-wraper">
          <ul className="contact-information">
            <li>
              <h1>Contact Information</h1>
            </li>
            <li>
              <a>
                <FontAwesomeIcon icon={faMapMarkerAlt} />
                20055 Ann Arbor Trail, Dearborn Heights, MI 48127
              </a>
            </li>

            <li>
              <a href="tel:(313)914-2720">
                <FontAwesomeIcon icon={faPhoneAlt} />
                (961) 914-2720
              </a>
            </li>
            <li>
              <a href="mailto:hassansoufan@hotmail.com">
                <FontAwesomeIcon icon={faEnvelope} />
                hassansoufan@hotmail.com
              </a>
            </li>
          </ul>
          <div className="rightOrange bgOragnge"></div>
        </div>
      </div>
    </main>
  );
}

export default Contact;
