import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { Link } from "react-router-dom";
import path from "../../config/path";
import { useStateValue } from "../../config/StateProvider";
import { auth } from "../../config/firebaseConfig";
import Images from "../../config/Images";
import { getCategories } from "../../getData/Elastic_search";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LeftMenuNav from "./LeftMenuNav";
// import LeftShowCategory from "./LeftShowCategory";
import { faBars, faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import Navs from "./Navs";
import SearchForm from "./SearchForm";
import LeftShowCategory from "./LeftShowCategory";
import EventHandler from "../SubComponents/EventHandler";
import TopHeader from "./TopHeader";

function Header() {
  const [{ user, basket, showCategories }, dispatch] = useStateValue();
  const navigate = useNavigate();
  const [show, setShow] = useState("");
  const [categoryList, setCategoryList] = useState({});
  const [shadow, setShadow] = useState("none");
  const [searchValue, setSearchValue] = useState("");
  const [showNavMenu, setShowNavMenu] = useState("");
  const [active, setActive] = useState(["", "", "", "", ""]);
  const [activePage, setActivePage] = useState("");
  const location = useLocation();
  useEffect(() => {
    setActivePage(location.pathname);
  }, [location]);
  const SignOut = () => {
    try {
      auth.signOut().then(() => {
        navigate(path.home);
      });
    } catch (e) {}
  };
  useEffect(() => {
    if (showCategories === "0") {
      setShadow("block");
    } else if (showCategories === "") {
      setShadow("none");
    }
  }, [showCategories]);
  const handleExist = () => {
    EventHandler(
      () => {
        SignOut();
      },
      "Sign Out",
      "",
      "Yes"
    );
  };
  const toggleCategories = (leftValue) => {
    // console.log("leftValue", leftValue);
    dispatch({
      type: "TOGGLE_SHOW_CATEGORIES",
      showCategories: leftValue,
    });
  };
  const ShowCategory = () => {
    // setShow("0");
    toggleCategories("0");
    setShadow("block");
  };
  // const ShowCategory = () => {
  //   setShow("0");
  //   setShadow("block");
  // };
  // const HideCategory = (change) => {
  //   setShadow("none");
  //   setShow("-110vw");
  //   setShowNavMenu("-110vw");
  // };
  const HideCategory = (change) => {
    setShadow("none");
    // setShow("-110vw");
    toggleCategories("-110vw");
    setShowNavMenu("-110vw");
  };
  const ShowNavMenu = () => {
    setShowNavMenu("0");
    setShadow("block");
  };

  const HideNavMenu = () => {
    setShowNavMenu("-110vw");
    setShadow("none");
    HideCategory();
  };

  const ProductSearch = (e) => {
    e.preventDefault();

    if (searchValue.trim().length != 0) {
      navigate(path.productList + "/type/search/" + searchValue.trim());
    } else {
      navigate(path.productList);
    }
  };

  useEffect(() => {
    getCategories().then((result) => {
      const temp = [];
      result.data.hits.hits.map((hit, index) => {
        const obj = Object.assign({}, hit._source);
        obj.id = index;
        temp.push(obj);
      });
      setCategoryList(temp[0].categoryList);
    });
  }, []);

  return (
    <header>
      <TopHeader />
      <div className="second-header">
        <div className="burger-menu" onClick={() => ShowNavMenu()}>
          <FontAwesomeIcon icon={faBars} />
        </div>
        <Link to={path.home} className="image-wrapper">
          <img src={Images.logo2} alt={Images.logo2} />
        </Link>
        <div className="menu-icon"></div>
        <div className="header-content"></div>
        <Navs activePage={activePage} />
        <SearchForm ProductSearch={ProductSearch} />
        {user ? (
          <div onClick={handleExist} className="auth">
            Sign Out
          </div>
        ) : (
          <div className="auth">
            <Link to={path.login}>Login</Link>
          </div>
        )}

        <Link
          to={user != null ? path.order : path.login}
          // className={"cart-container " + active[3]}
          className={
            activePage === "cart"
              ? "cart-container active-page"
              : "cart-container"
          }
        >
          <div className="content">
            <span>{Object.keys(basket).length}</span>
            {/* <ShoppingCartIcon className="cart-icon"></ShoppingCartIcon> */}
            {/* <FontAwesomeIcon icon={faShoppingCart} className="cart-icon" /> */}
            <img src={Images.cart} />
          </div>
        </Link>

        <div
          onClick={() => HideCategory()}
          className="shadow"
          style={{ display: shadow }}
        ></div>
        <div
          onClick={() => HideNavMenu()}
          className="shadow"
          style={{ display: shadow }}
        ></div>

        <LeftMenuNav
          showNavMenu={showNavMenu}
          active={active}
          HideNavMenu={HideNavMenu}
          ShowCategory={ShowCategory}
          handleExist={handleExist}
        />
        <LeftShowCategory
          show={show}
          categoryList={categoryList}
          HideCategory={HideCategory}
          HideNavMenu={HideNavMenu}
        />
      </div>
    </header>
  );
}

export default Header;
