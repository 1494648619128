import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";

const Pagination = ({ postsPerPage, totalPosts, paginate, productList }) => {
  const pageNumbers = [];
  const { page } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    if (page != null) {
      setCurrentPage(page);
    }
  }, [page]);

  useEffect(() => {
    if (document.getElementsByClassName("active-page")[0] != undefined) {
      document.getElementsByClassName("pagination")[0].scrollTo({
        left:
          document.getElementsByClassName("active-page")[0].offsetLeft -
          document.getElementsByClassName("pagination")[0].offsetLeft -
          39,
        behavior: "smooth",
      });
    }
  }, [productList]);

  for (let i = 1; i <= totalPosts; i++) {
    pageNumbers.push(i);
  }

  return (
    <ul className="pagination">
      {pageNumbers.map((number) => (
        <li key={number}>
          <Link
            to={
              (page != null
                ? window.location.pathname.split("page/" + page)[0]
                : window.location.pathname[
                    window.location.pathname.length - 1
                  ] == "/"
                ? ""
                : window.location.pathname + "/") +
              "page/" +
              number
            }
            onClick={() => paginate(number)}
            className={number == currentPage ? "active-page" : ""}
          >
            {number}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default Pagination;
