import React from "react";
import ProductContent from "../../Orders/ProductContent";
import SpinnerRotate from "../SpinnerRotate";
// import TableRow from "./TableRow";

function TableView({ tableCaption, columns, data, loading }) {
  return (
    <table>
      {/* <caption> {true && tableCaption} </caption> */}
      <caption>{tableCaption()}</caption>
      <thead>
        <tr>
          {columns.map((element, index) => (
            <TableHeadTH element={element} key={index} />
          ))}
        </tr>
      </thead>

      <tbody>
        {data.map((itemObject, idx) => {
          return <TableRow key={idx} item={itemObject} columns={columns} />;
        })}
      </tbody>
    </table>
  );
}
const TableHeadTH = ({ element }) => (
  <th className={element.thStyle}>
    {/* render head or just a text-th  {element.headerName} */}
    {!element.renderHeader ? (
      <>{element.headerName}</>
    ) : (
      <>{element.renderHeader()}</>
    )}
  </th>
);
const TableRow = ({ item, columns }) => (
  <>
    <tr>
      {columns.map((element, index) => (
        <td
          key={index}
          // style={
          //   element.minMaxwidth
          //     ? {
          //         width: `${element.minMaxwidth}`,
          //         minWidth: `${element.minMaxwidth}`,
          //         maxWidth: `${element.minMaxwidth}`,
          //       }
          //     : null
          // }
          className={element.tdCellStyle ? `${element.tdCellStyle}` : null}
        >
          <div
            className="td-cell"
            style={
              element.minMaxwidth
                ? {
                    width: `${element.minMaxwidth}`,
                    maxWidth: `${element.minMaxwidth}`,
                    // maxWidth: `${element.minMaxwidth}`,
                  }
                : null
            }
          >
            {!element.renderCell ? (
              <>{item[`${element.field}`]}</>
            ) : (
              <>{element.renderCell({ ...item, index: index })}</>
            )}
          </div>
        </td>
      ))}
    </tr>
    {/* <tr>
      <div className="nested-div-table">
        {Object.values(item.products).map((item, idx) => (
          <>
            <ProductContent key={idx} product={item} />
          </>
        ))}
      </div>
    </tr> */}
  </>
);
export default TableView;
{
  /* <TableRow data={data} columns={columns} /> */
}
