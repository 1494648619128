import React from "react";
import Images from "../../config/Images";
import { useStateValue } from "../../config/StateProvider";
import CloseIcon from "@material-ui/icons/Close";
import path from "../../config/path";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faInfoCircle,
  faPhone,
  faPlusCircle,
  faShoppingBag,
  faStore,
  faTruck,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import {
  FaCaretDown,
  FaClock,
  FaCubes,
  FaShoppingBasket,
  FaSignInAlt,
  FaSignOutAlt,
  FaUserEdit,
} from "react-icons/fa";
import { useEffect } from "react";
import { useState } from "react";

function LeftMenuNav({
  showNavMenu,
  active,
  HideNavMenu,
  ShowCategory,
  handleExist,
}) {
  const [{ user }, dispatch] = useStateValue();
  const [activePage, setActivePage] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setActivePage(location.pathname);
  }, [location]);
  return (
    <section className="nav-menu" style={{ left: showNavMenu }}>
      <div className="container">
        <div className="nav-top-welcome">
          <img src={Images.logoWhite} alt={Images.logoWhite} />
          <h1>Welcome</h1>
          {user ? <p>{user.email}</p> : null}
        </div>

        <CloseIcon className="close" onClick={() => HideNavMenu()}></CloseIcon>
        <nav>
          <ul>
            <li>
              <Link
                to={path.home}
                className={activePage === path.home ? "active-page" : ""}
                onClick={() => HideNavMenu()}
              >
                <FontAwesomeIcon icon={faHome} />
                Home
              </Link>
            </li>

            <li>
              <Link
                to={path.about}
                className={activePage === path.about ? "active-page" : ""}
                onClick={() => HideNavMenu()}
              >
                <FontAwesomeIcon icon={faInfoCircle} />
                About
              </Link>
            </li>
            <li>
              <Link
                to={path.contact}
                className={activePage === path.contact ? "active-page" : ""}
                onClick={() => HideNavMenu()}
              >
                <FontAwesomeIcon icon={faPhone} />
                Contact us
              </Link>
            </li>
            <li onClick={() => ShowCategory()}>
              <a> Categories</a>
              <ArrowForwardIosIcon className="arrowForward" />
            </li>
            {user && user.role === 2 ? (
              <>
                {/* <li className="flexBtw">
                  <h2>admin</h2>
                  <FaCaretDown />
                </li> */}
                <ul className="admin-navs accordion">
                  <li>
                    <Link
                      to={path.users}
                      className={activePage === path.users ? "active-page" : ""}
                      onClick={() => HideNavMenu()}
                    >
                      <FaUserEdit />
                      Users
                    </Link>
                  </li>

                  <li>
                    <Link
                      to={path.stock}
                      className={activePage === path.stock ? "active-page" : ""}
                      onClick={() => HideNavMenu()}
                    >
                      <FaCubes />
                      Stock
                    </Link>
                  </li>

                  <li>
                    <Link
                      to={path.orders}
                      className={
                        activePage === path.orders ? "active-page" : ""
                      }
                      onClick={() => HideNavMenu()}
                    >
                      <FaShoppingBasket />
                      Orders
                    </Link>
                  </li>

                  {/*<li>*/}
                  {/*  <Link*/}
                  {/*    to={path.scheduals}*/}
                  {/*    className={*/}
                  {/*      activePage === path.scheduals ? "active-page" : ""*/}
                  {/*    }*/}
                  {/*    onClick={() => HideNavMenu()}*/}
                  {/*  >*/}
                  {/*    <FaClock />*/}
                  {/*    Schedule*/}
                  {/*  </Link>*/}
                  {/*</li>*/}
                </ul>
              </>
            ) : null}

            <li>
              {user ? (
                <a
                  onClick={() => {
                    HideNavMenu();
                    handleExist();
                  }}
                >
                  <FaSignOutAlt />
                  sign out
                </a>
              ) : (
                <a
                  onClick={() => {
                    HideNavMenu();
                    navigate(path.login);
                  }}
                >
                  <FaSignInAlt />
                  log in
                </a>
              )}
            </li>
          </ul>
        </nav>
      </div>
      {/* <FiltersSearch changeSearchingFields={changeSearchingFields} /> */}
    </section>
  );
}

export default LeftMenuNav;
