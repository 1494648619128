import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import SwiperCore, { Pagination, Autoplay, Navigation } from "swiper";
import { getByParentCategory } from "../../getData/Elastic_search";
import path from "../../config/path";
import { Link } from "react-router-dom";

function SimilarProducts({ parentCategory }) {
  const [productCatList, setProductCatList] = useState([]);
  let topProductSize = 20;
  let hasSwiper = true;
  SwiperCore.use([Autoplay, Pagination, Navigation]);
  const getSimilarProducts = async () => {
    let productsCat = [];
    await getByParentCategory(parentCategory, 0, topProductSize).then(
      (result) => {
        result.data.hits.hits.map((hit) => {
          productsCat = [...productsCat, hit._source];
        });
      }
    );
    setProductCatList(productsCat);
  };
  useEffect(() => {
    getSimilarProducts();
    // return () => {};
  }, []);
  return (
    <section className="top-section">
      <h1>{parentCategory}</h1>
      {hasSwiper ? (
        <Swiper
          spaceBetween={20}
          navigation={true}
          slidesPerView={"auto"}
          className="mySwiper"
          // loop={true}
        >
          {productCatList.map((productcat, key) => (
            <SwiperSlide key={key}>
              <div className="image-wrapper">
                <Link to={path.detail + "/" + productcat.Item_Number}>
                  <img src={productcat.images} alt={productcat.images} />
                </Link>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <ul>
          {productCatList.map((productcat) => (
            <li>
              <div className="image-wrapper">
                <Link to={path.detail + "/" + productcat.Item_Number}>
                  <img src={productcat.images} alt={productcat.images} />
                </Link>
              </div>
            </li>
          ))}
        </ul>
      )}
    </section>
  );
}

export default SimilarProducts;
