import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { FaCalendar, FaCalendarCheck, FaRegBookmark } from "react-icons/fa";
import Images from "../../config/Images";
import { useStateValue } from "../../config/StateProvider";
import EventHandler from "../SubComponents/EventHandler";

function CheckoutPopup({
  popUp,
  ClosePopUp,
  userRequest,
  changeProperyValue,
  radioValue,
  handleCheckout,
  total_cost,
  schedualedMonthly,
  validateUserRequestFields,
}) {
  const [{ user }] = useStateValue();

  useEffect(() => {
    // CHECK REQUIRED FIELDS
    // console.log("userRequest", userRequest);
    validateUserRequestFields();
  }, []);
  return (
    <div
      className="checkout-popup"
      style={{ display: popUp }}
      // onClick={() => ClosePopUp()}
    >
      <div className="popup-content">
        <div className="popup-top">
          <h1>
            {schedualedMonthly ? (
              <>
                scheduled order <FaCalendarCheck />
              </>
            ) : (
              "place order"
            )}
          </h1>
          <div className="closepopup" onClick={() => ClosePopUp()}>
            <FontAwesomeIcon icon={faXmark} />
          </div>
        </div>
        {user?.switched_user ? (
          <>
            <fieldset>
              <legend>Customer</legend>
              <ul>
                <li>
                  <small>Number</small>
                  <span>{user.switched_user.Customer_Number}</span>
                </li>
                <li>
                  <small>Company</small>
                  <span>{user.switched_user.Company}</span>
                </li>
                <li>
                  <small>TAXID</small>
                  <span>{user.switched_user.TAXID}</span>
                </li>
              </ul>
            </fieldset>
            <ul>
              <li className="li-note-wraper">
                <FaRegBookmark />
                <textarea
                    onChange={(e) => {
                      changeProperyValue("note", e.target.value);
                      validateUserRequestFields();
                    }}
                    placeholder="Note"
                    value={userRequest.note}
                />
              </li>
              <li>
                <small>Total: </small>
                <span> $ {total_cost.toFixed(2)}</span>
              </li>
            </ul>
            <button onClick={handleCheckout}>Confirm </button>
          </>
        ) : (
          <>
            <ul>
              <li>
                <small>Total: </small>
                <span> $ {total_cost.toFixed(2)}</span>
              </li>
              {radioValue != "pickup" ? (
                <>
                  <li className="li-inputs-wraper">
                    <img src={Images.directionsIcon} />
                    <input
                      onChange={(e) => {
                        changeProperyValue("location", e.target.value);
                        validateUserRequestFields();
                      }}
                      placeholder="Address"
                      value={userRequest.location}
                    />
                    <div className="error">{userRequest.locationError}</div>
                  </li>
                  <li className="li-inputs-wraper">
                    <img src={Images.streetMapIcon} />
                    <input
                      onChange={(e) => {
                        changeProperyValue("street", e.target.value);
                        validateUserRequestFields();
                      }}
                      placeholder="Street"
                      value={userRequest.street}
                    />
                    <div className="error">{userRequest.streetError}</div>
                  </li>
                </>
              ) : null}
              <li className="li-note-wraper">
                <FaRegBookmark />
                <textarea
                  onChange={(e) => {
                    changeProperyValue("note", e.target.value);
                    validateUserRequestFields();
                  }}
                  placeholder="Note"
                  value={userRequest.note}
                />
              </li>
            </ul>
            <button
              onClick={handleCheckout}
              // className={
              //   userRequest.location != "" || radioValue === "pickup"
              //     ? "active-confirm-order"
              //     : null
              // }
              // disabled={
              //   (userRequest.location == "" || userRequest.street == "") &&
              //   radioValue === "delivery"
              //     ? true
              //     : false
              // }
              className={
                (userRequest.location == "" && radioValue == "delivery") ||
                (userRequest.street == "" && radioValue == "delivery")
                  ? null
                  : "active-confirm-order"
              }
            >
              Confirm
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default CheckoutPopup;
