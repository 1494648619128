import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { getCategories } from "../../getData/Elastic_search";
import path from "../../config/path";
import Category from "./Category";
import { useStateValue } from "../../config/StateProvider";
import { Link } from "react-router-dom";

function CategoriesList() {
  const [{ categoryList }] = useStateValue();

  return (
    <section className="category-list">
      <div className="container">
        <div className="categoriesTop">
          <FontAwesomeIcon icon={faBars} />
          <h1> Categories</h1>
        </div>

        <ul>
          <li>
            <Link to={path.productList}>All</Link>
          </li>
          {Object.entries(categoryList).map(([key, value]) => (
            <Category parentCategory={key} subCategory={value} key={key} />
          ))}
        </ul>
      </div>
    </section>
  );
}

export default CategoriesList;
