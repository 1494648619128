import { FaCircleNotch, FaSpinner } from "react-icons/fa";

import React from "react";

function SpinnerRotate() {
  return (
    <div className="spinner-wrapper">
      <FaCircleNotch className="rotate-spinner" />
    </div>
  );
}

export default SpinnerRotate;
