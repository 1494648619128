const real = {
  firebaseConfig: {
    apiKey: `${process.env.REACT_APP_API_KEY}`,
    authDomain: `${process.env.REACT_APP_Authdomain}`,
    projectId: `${process.env.REACT_APP_Projectid}`,
    storageBucket: `${process.env.REACT_APP_Storagebuget}`,
    messagingSenderId: `${process.env.REACT_APP_Messeginfsenderid}`,
    appId: `${process.env.REACT_APP_Appid}`,
    measurementId: `${process.env.REACT_APP_Measurmentid}`,
  },
  elastic_config: {
    elastic_host: `${process.env.REACT_APP_ELASTIC_HOST}`,
    auth_key: process.env.REACT_APP_ELASTIC_API_KEY,
  },
  // domain_site: `${process.env.REACT_APP_DOMAIN_SITE}`,
};

const ideas_testing = {
  firebaseConfig: {
    apiKey: `${process.env.REACT_APP_API_KEY_TEST}`,
    authDomain: `${process.env.REACT_APP_Authdomain_TEST}`,
    projectId: `${process.env.REACT_APP_Projectid_TEST}`,
    storageBucket: `${process.env.REACT_APP_Storagebuget_TEST}`,
    messagingSenderId: `${process.env.REACT_APP_Messeginfsenderid_TEST}`,
    appId: `${process.env.REACT_APP_Appid_TEST}`,
    measurementId: `${process.env.REACT_APP_Measurmentid_TEST}`,
  },
  elastic_config: {
    elastic_host: `${process.env.REACT_APP_ELASTIC_HOST1}`,
    auth_key: process.env.REACT_APP_ELASTIC_API_KEY1,
  },
  // domain_site: `${process.env.REACT_APP_DOMAIN_SITE}`,
};

export const config = real;
