import axios from "axios";
import { config } from "../config/config";
const elastic_host = config.elastic_config.elastic_host;
const auth_key = config.elastic_config.auth_key;

export const checkUserByTaxId = async (taxid) => {
  try {
    const query_items = {
      query: {
        match: {
          TAXID: taxid,
        },
      },
    };
    const result = await axios.get(`${elastic_host}/phxposusers/_search`, {
      params: {
        source: JSON.stringify(query_items),
        source_content_type: "application/json",
      },
      headers: {
        Authorization: auth_key,
      },
    });

    return result;
  } catch (error) {}
};

export const getUserByEmail = async (email) => {
  try {
    const query_items = {
      query: {
        match: {
          "email.keyword": email,
        },
      },
    };
    const result = await axios.get(`${elastic_host}/phxusers/_search`, {
      params: {
        source: JSON.stringify(query_items),
        source_content_type: "application/json",
      },
      headers: {
        Authorization: auth_key,
      },
    });
    // console.log("123", result);
    return result;
  } catch (error) {}
};
export const getStoreProducts = async (from, size) => {
  try {
    const query_items = {
      size: size,
      from: from,
      // sort: [
      //   {
      //     "Description.keyword": {
      //       order: "asc",
      //     },
      //   },
      // ],
      query: {
        // range: {
        //   quantity: {
        //     gte: 0,
        //   },
        // },
        match_all: {},
      },
    };
    const result = await axios.get(`${elastic_host}/phxproducts/_search`, {
      params: {
        source: JSON.stringify(query_items),
        source_content_type: "application/json",
      },
      headers: {
        Authorization: auth_key,
      },
    });

    return result;
  } catch (error) {}
};
export const getLastUpdatedProducts = async (from, size) => {
  try {
    const query_items = {
      size: size,
      from: from,
      sort: [{ "timestamp._seconds": { order: "desc" } }],
      query: {
        match_all: {},
      },
    };
    const result = await axios.get(`${elastic_host}/phxproducts/_search`, {
      params: {
        source: JSON.stringify(query_items),
        source_content_type: "application/json",
      },
      headers: {
        Authorization: auth_key,
      },
    });
    // console.log("latest items", result);
    return result;
  } catch (error) {}
};
export const getById = async (id) => {
  try {
    const query_items = {
      size: 20,
      query: {
        match: {
          Item_Number: id,
        },
      },
    };
    const result = await axios.get(`${elastic_host}/phxproducts/_search`, {
      params: {
        source: JSON.stringify(query_items),
        source_content_type: "application/json",
      },
      headers: {
        Authorization: auth_key,
      },
    });
    // console.log("resukt", result);
    return result;
  } catch (error) {}
};

export const getByParentCategory = async (categoryString, from, size) => {
  try {
    const query_items = {
      from: from,
      size: size,
      sort: [
        {
          "Description.keyword": {
            order: "asc",
          },
        },
      ],
      query: {
        bool: {
          must: [
            {
              bool: {
                should: [
                  {
                    term: {
                      "Category.keyword": {
                        value: categoryString,
                      },
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    };
    const result = await axios.get(`${elastic_host}/phxproducts/_search`, {
      params: {
        source: JSON.stringify(query_items),
        source_content_type: "application/json",
      },
      headers: {
        Authorization: auth_key,
      },
    });
    return result;
  } catch (error) {}
};
export const getBySubCategory = async (
  categoryString,
  subcategoryString,
  from,
  size
) => {
  try {
    const query_items = {
      from: from,
      size: size,
      sort: [
        {
          "Description.keyword": {
            order: "asc",
          },
        },
      ],
      query: {
        bool: {
          must: [
            {
              bool: {
                must: [
                  {
                    term: {
                      "Category.keyword": {
                        value: categoryString,
                      },
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    };
    const result = await axios.get(`${elastic_host}/phxproducts/_search`, {
      params: {
        source: JSON.stringify(query_items),
        source_content_type: "application/json",
      },
      headers: {
        Authorization: auth_key,
      },
    });

    return result;
  } catch (error) {}
};
export const getBySearch = async (searchString, from, size) => {
  // console.log("searchString", searchString);
  try {
    const query_items = {
      size: size,
      from: from,
      // kkk
      sort: [{ "timestamp._seconds": { order: "desc" } }],
      query: {
        bool: {
          should: [
            {
              wildcard: {
                "Description.keyword": {
                  case_insensitive: true,
                  value: "*" + searchString.trim() + "*",
                },
              },
            },
          ],
        },
      },
    };
    const result = await axios.get(`${elastic_host}/phxproducts/_search`, {
      params: {
        source: JSON.stringify(query_items),
        source_content_type: "application/json",
      },
      headers: {
        Authorization: auth_key,
      },
    });

    return result;
  } catch (error) {}
};
// export const getDiscountProducts = async (from, size) => {
//   try {
//     const query_items = {
//       size: size,
//       from: from,
//       sort: [{ "timestamp._seconds": { order: "desc" } }],
//       query: {
//         bool: {
//           must: [
//
//             {
//               bool: {
//                 must: [
//                   {
//                     match: {
//                       discount: true,
//                     },
//                   },
//                 ],
//               },
//             },
//           ],
//         },
//       },
//     };
//     const result = await axios.get(`${elastic_host}/phxproducts/_search`, {
//       params: {
//         source: JSON.stringify(query_items),
//         source_content_type: "application/json",
//       },
//       headers: {
//         Authorization: auth_key,
//       },
//     });
//     // console.log("discounted", result);
//     return result;
//   } catch (error) {}
// };
export const getCategories = async () => {
  try {
    const query_items = {
      query: {
        match_all: {},
      },
    };
    const result = await axios.get(`${elastic_host}/phxadminsettings/_search`, {
      params: {
        source: JSON.stringify(query_items),
        source_content_type: "application/json",
      },
      headers: {
        Authorization: auth_key,
      },
    });

    return result;
  } catch (error) {}
};
export const getShipmentCost = async () => {
  try {
    const query_items = {
      query: {
        match_all: {},
      },
    };
    const result = await axios.get(`${elastic_host}/phxadminsettings/_search`, {
      params: {
        source: JSON.stringify(query_items),
        source_content_type: "application/json",
      },
      headers: {
        Authorization: auth_key,
      },
    });

    return result;
  } catch (error) {}
};
export const getStoreProductsCount = async () => {
  try {
    const query_items = {
      query: {
        //   status: 1,

        // qty > 0
        match_all: {},
      },
    };
    const result = await axios.get(`${elastic_host}/phxproducts/_count`, {
      params: {
        source: JSON.stringify(query_items),
        source_content_type: "application/json",
      },
      headers: {
        Authorization: auth_key,
      },
    });

    return result;
  } catch (error) {}
};
export const getBySubCategoryCount = async (
  categoryString,
  subcategoryString
) => {
  try {
    const query_items = {
      query: {
        bool: {
          must: [
            {
              bool: {
                must: [
                  {
                    term: {
                      "Category.keyword": {
                        value: categoryString,
                      },
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    };
    const result = await axios.get(`${elastic_host}/phxproducts/_count`, {
      params: {
        source: JSON.stringify(query_items),
        source_content_type: "application/json",
      },
      headers: {
        Authorization: auth_key,
      },
    });

    return result;
  } catch (error) {}
};
export const getByParentCategoryCount = async (categoryString) => {
  try {
    const query_items = {
      query: {
        bool: {
          must: [
            {
              bool: {
                should: [
                  {
                    term: {
                      "Category.keyword": {
                        value: categoryString,
                      },
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    };
    const result = await axios.get(`${elastic_host}/phxproducts/_count`, {
      params: {
        source: JSON.stringify(query_items),
        source_content_type: "application/json",
      },
      headers: {
        Authorization: auth_key,
      },
    });

    return result;
  } catch (error) {}
};
export const getBySearchCount = async (searchString) => {
  try {
    const query_items = {
      query: {
        bool: {
          must: [
            {
              bool: {
                should: [
                  {
                    wildcard: {
                      "Description.keyword": {
                        value: "*" + searchString + "*",
                      },
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    };
    const result = await axios.get(`${elastic_host}/phxproducts/_count`, {
      params: {
        source: JSON.stringify(query_items),
        source_content_type: "application/json",
      },
      headers: {
        Authorization: auth_key,
      },
    });

    return result;
  } catch (error) {}
};

// export const getDiscountProductsCount = async () => {
//   try {
//     const query_items = {
//       query: {
//         bool: {
//           must: [
//
//             {
//               bool: {
//                 must: [
//                   {
//                     match: {
//                       discount: true,
//                     },
//                   },
//                 ],
//               },
//             },
//           ],
//         },
//       },
//     };
//     const result = await axios.get(`${elastic_host}/phxproducts/_count`, {
//       params: {
//         source: JSON.stringify(query_items),
//         source_content_type: "application/json",
//       },
//       headers: {
//         Authorization: auth_key,
//       },
//     });

//     return result;
//   } catch (error) {}
// };

export const getUsers = async (from, to, userFilters) => {
  try {
    // userId: "",
    // userTaxId: "",
    // userPhone: "",
    // userNumber: "",
    // userPricingCode: "",
    // console.log("userFilters", userFilters);
    let mainQuery = {};
    let must_query = [];

    if (
      userFilters.userTaxId === "" &&
      userFilters.userNumber === "" &&
      userFilters.userPhone === ""
    ) {
      mainQuery = {
        match_all: {},
      };
    } else {
      if (userFilters.userTaxId != "") {
        must_query = [
          ...must_query,
          {
            wildcard: {
              TAXID: {
                value: `*${userFilters.userTaxId}*`,
              },
            },
          },
        ];
      }
      if (userFilters.userNumber != "") {
        must_query = [
          ...must_query,
          {
            wildcard: {
              Customer_Number: {
                value: `*${userFilters.userNumber}*`,
              },
            },
          },
        ];
      }

      mainQuery = {
        bool: {
          must: must_query,
        },
      };
    }
    const select_users = {
      from: from,
      size: to,
      // sort: [{ "timestamp._seconds": { order: "desc" } }],
      sort: [{ "TAXID.keyword": { order: "asc" } }],
      query: mainQuery,
    };
    // console.log("Q users", select_users);
    const result = await axios.get(`${elastic_host}/phxposusers/_search`, {
      params: {
        source: JSON.stringify(select_users),
        source_content_type: "application/json",
      },
      headers: {
        Authorization: auth_key,
      },
    });
    // console.log("getUsers", result);
    return result;
  } catch (e) {
    // console.log("failed", e);
  }
};
export const countPosUsers = async (userFilters) => {
  try {
    let mainQuery = {};
    let must_query = [];

    if (
      userFilters.userTaxId === "" &&
      userFilters.userNumber === "" &&
      userFilters.userPhone === ""
    ) {
      mainQuery = {
        match_all: {},
      };
    } else {
      if (userFilters.userTaxId != "") {
        must_query = [
          ...must_query,
          {
            wildcard: {
              TAXID: {
                value: `*${userFilters.userTaxId}*`,
              },
            },
          },
        ];
      }
      if (userFilters.userNumber != "") {
        must_query = [
          ...must_query,
          {
            wildcard: {
              Customer_Number: {
                value: `*${userFilters.userNumber}*`,
              },
            },
          },
        ];
      }

      mainQuery = {
        bool: {
          must: must_query,
        },
      };
    }
    const countUsers = {
      query: mainQuery,
    };

    const result = await axios.get(`${elastic_host}/phxposusers/_count`, {
      params: {
        source: JSON.stringify(countUsers),
        source_content_type: "application/json",
      },
      headers: {
        Authorization: auth_key,
      },
    });
    // console.log("counmt", result);
    return result;
  } catch (e) {}
};

export const getChedualOfUser = async (toUser) => {
  // console.log("getChedualOfUser", toUser);
  const query = {
    query: {
      match: {
        "scheduleuserid.keyword": toUser.Customer_Number,
      },
    },
  };
  // console.log("schedual query", query);
  const result = await axios.get(
    `${elastic_host}/phxschedualedorders/_search`,
    {
      params: {
        source: JSON.stringify(query),
        source_content_type: "application/json",
      },
      headers: {
        Authorization: auth_key,
      },
    }
  );
  // console.log("getChedualOfUser", result.data.hits.hits);
  return result;
};
export const getAllScheduals = async (userFilters) => {
  // console.log("userFilters", userFilters);
  let query_scheduals = {
    from: 0,
    size: 100,
    sort: [{ "timestamp._seconds": { order: "asc" } }],
    query: {
      match_all: {},
    },
  };
  if (userFilters) {
    if (userFilters.userNumber) {
      query_scheduals = {
        query: {
          match: {
            "scheduleuserid.keyword": userFilters.userNumber,
          },
        },
      };
    }
  }

  // if (userSchedual.status != "any") {
  //   query_scheduals = {
  //     query: {
  //       match: {
  //         status: userSchedual.status,
  //       },
  //     },
  //   };
  // }
  // console.log("schedual query", query_scheduals);
  const result = await axios.get(
    `${elastic_host}/phxschedualedorders/_search`,
    {
      params: {
        source: JSON.stringify(query_scheduals),
        source_content_type: "application/json",
      },
      headers: {
        Authorization: auth_key,
      },
    }
  );
  // console.log("getChedualOfUser", result.data.hits.hits);
  return result;
};
export const getPosProducts = async (from, to, dataFilters, sortingBy) => {
  try {
    // productNumber: "",
    // productDescription: "",
    // category
    // console.log("dataFilters", dataFilters);
    let mainQuery = {};
    let must_query = [];
    // console.log("dataFilters", dataFilters);
    if (
      dataFilters.productNumber === "" &&
      dataFilters.productDescription === "" &&
      dataFilters.category === "" &&
      dataFilters.withPicture === ""
    ) {
      mainQuery = {
        match_all: {},
      };
    } else {
      if (dataFilters.productNumber != "") {
        // console.log("dataFilters.productNumber", dataFilters.productNumber);
        must_query = [
          ...must_query,
          {
            term: {
              Item_Number: {
                value: dataFilters.productNumber,
              },
            },
          },
        ];
      }
      if (dataFilters.productDescription != "") {
        must_query = [
          ...must_query,
          {
            wildcard: {
              "Description.keyword": {
                case_insensitive: true, //lll
                value: `*${dataFilters.productDescription}*`,
              },
            },
          },
        ];
      }
      if (dataFilters.category != "") {
        must_query = [
          ...must_query,
          {
            wildcard: {
              Description: {
                value: dataFilters.category,
              },
            },
          },
        ];
      }
      if (dataFilters.withPicture != "") {
        must_query = [
          ...must_query,
          {
            must: {
              withPicture: dataFilters.withPicture,
            },
          },
        ];
      }
      mainQuery = {
        bool: {
          must: must_query,
        },
      };
    }
    const select_products = {
      from: from,
      size: to,
      // sort: [{ "timestamp._seconds": { order: "desc" } }],
      // sort: [{ "Item_Number.keyword": { order: "asc" } }],
      sort: [{ Quantity: { order: "asc" } }],
      query: mainQuery,
    };
    // console.log("pos products Q", select_products);
    const result = await axios.get(`${elastic_host}/phxproducts/_search`, {
      params: {
        source: JSON.stringify(select_products),
        source_content_type: "application/json",
      },
      headers: {
        Authorization: auth_key,
      },
    });
    // console.log("getUsers", result);
    return result;
  } catch (e) {
    // console.log("failed", e);
  }
};
export const countPosProducts = async (dataFilters) => {
  try {
    // console.log("dataFilters", dataFilters);
    let mainQuery = {};
    let must_query = [];

    if (
      dataFilters.productNumber === "" &&
      dataFilters.productDescription === "" &&
      dataFilters.category === "" &&
      dataFilters.withPicture === ""
    ) {
      mainQuery = {
        match_all: {},
      };
    } else {
      if (dataFilters.productNumber != "") {
        // console.log("dataFilters.productNumber", dataFilters.productNumber);
        must_query = [
          ...must_query,
          {
            term: {
              Item_Number: {
                value: dataFilters.productNumber,
              },
            },
          },
        ];
      }
      if (dataFilters.productDescription != "") {
        must_query = [
          ...must_query,
          {
            wildcard: {
              "Description.keyword": {
                case_insensitive: true, //lll
                value: `*${dataFilters.productDescription}*`,
              },
            },
          },
        ];
      }

      if (dataFilters.category != "") {
        must_query = [
          ...must_query,
          {
            wildcard: {
              Description: {
                value: dataFilters.category,
              },
            },
          },
        ];
      }
      if (dataFilters.withPicture != "") {
        must_query = [
          ...must_query,
          {
            must: {
              withPicture: dataFilters.withPicture,
            },
          },
        ];
      }
      mainQuery = {
        bool: {
          must: must_query,
        },
      };
    }
    const countUsers = {
      query: mainQuery,
    };

    const result = await axios.get(`${elastic_host}/phxproducts/_count`, {
      params: {
        source: JSON.stringify(countUsers),
        source_content_type: "application/json",
      },
      headers: {
        Authorization: auth_key,
      },
    });
    // console.log("getUsers", result);
    return result;
  } catch (e) {}
};
export const fetchOrders = async (dataFilters) => {
  // console.log("fetchOrders", dataFilters);
  const exportQuery = {
    sort: [{ "timestamp._seconds": { order: "desc" } }],
    query: {
      match_all: {},
      // range: {
      //   timestamp: {
      //     gte: "now-1d/d",
      //     // lt: "now/d",
      //   },
      // },
    },
  };
  // console.log("exportQuery", exportQuery);
  try {
    const result = await axios.get(`${elastic_host}/phxrequests/_search`, {
      params: {
        source: JSON.stringify(exportQuery),
        source_content_type: "application/json",
      },
      headers: {
        Authorization: auth_key,
      },
    });
    return result;
  } catch (e) {}
};
export const fetchSingleRequest = async (requestid) => {
  // console.log("fetchOrders", dataFilters);
  const exportQuery = {
    sort: [{ "timestamp._seconds": { order: "desc" } }],
    query: {
      match:{
        requestid:requestid
      }
    },
  };
  // console.log("exportQuery", exportQuery);
  try {
    const result = await axios.get(`${elastic_host}/phxrequests/_search`, {
      params: {
        source: JSON.stringify(exportQuery),
        source_content_type: "application/json",
      },
      headers: {
        Authorization: auth_key,
      },
    });
    return result;
  } catch (e) {}
};
export const countOrders = async () => {
  const countQ = {
    query: {
      match_all: {},
    },
  };

  try {
    const result = await axios.get(`${elastic_host}/phxrequests/_count`, {
      params: {
        source: JSON.stringify(countQ),
        source_content_type: "application/json",
      },
      headers: {
        Authorization: auth_key,
      },
    });
    return result;
  } catch (e) {}
};

export const fetchUsersNotInPos = async () => {
  const query_notInPos = {
    query: {
      bool: {
        must_not: {
          exists: {
            field: "posUserInfo",
          },
        },
      },
    },
  };

  const result = await axios.get(`${elastic_host}/phxusers/_search`, {
    params: {
      source: JSON.stringify(query_notInPos),
      source_content_type: "application/json",
    },
    headers: {
      Authorization: auth_key,
    },
  });
};

export const fetchTotalRequestedProducts = async () => {
  const q_count_requested = {
    size: 1,
    aggs: {
      productsrequest: {
        string_stats: { field: "products.productId.keyword" },
      },
    },
  };

  const result = await axios.get(`${elastic_host}/phxrequests/_search?pretty`, {
    params: {
      source: JSON.stringify(q_count_requested),
      source_content_type: "application/json",
    },
    headers: {
      Authorization: auth_key,
    },
  });
  if (result) {
    return result;
  } else {
    return {};
  }
};
