import React, { useCallback, useEffect, useState } from "react";
import { useStateValue } from "../../config/StateProvider";
import { updateSchuale } from "../../writeData/FirebaseFunc";
import EventHandler from "../SubComponents/EventHandler";
function SchedualSubTotal() {
  const [{ user, shippingCost, basket }, dispatch] = useStateValue();
  const [items_length, setItem_length] = useState(0);
  const [total_cost, setTotalCost] = useState(0);
  const [radioValue, setRadioValue] = useState("");
  const [shipmentCost, setShipmentCost] = useState(0);
  const [userRequest, setUserRequest] = useState({});

  const handleChangeShipping = (e) => {
    setRadioValue(e.target.value);

    if (e.target.value === "pickup") {
      setShipmentCost(0);
    } else {
      setShipmentCost(shippingCost);
    }
  };

  const basketLength = () => {
    let temp_totals = Object.values(basket).reduce(
      (prevTotal, nextElement) => prevTotal + nextElement.quantity,
      0
    );
    return temp_totals;
  };
  const updateTotalCost = (initialCost) => {
    let temp_total_cost = Object.values(basket).reduce(
      (prevCost, next_Object) =>
        prevCost + next_Object.quantity * next_Object.Price,
      initialCost
    );
    return temp_total_cost;
  };
  const handle_Schedual_Update = () => {
    EventHandler(
      () =>
        updateSchuale(
          user.switched_user.hasSchedual,
          basket,
          total_cost,
          radioValue
        ),
      "Update Schedule",
      "",
      "Yes"
    );
  };

  useEffect(() => {
    // console.log("user", user);
    if (user) {
      setUserRequest({
        name: user.name,
        location: "",
        phone: "",
        email: user.email,
        nameError: "",
        passwordError: "",
        locationError: "",
      });

      // console.log(user.switched_user.hasSchedual.shippingType);
    }
  }, [user]);
  useEffect(() => {
    // console.log("cart bakset", basket);
    if (radioValue == "pickup") {
      setTotalCost(updateTotalCost(0));
    } else {
      setTotalCost(updateTotalCost(shipmentCost));
    }
    setItem_length(basketLength());
  }, [{ basket, radioValue }]);
  return (
    <section
      className={
        user.switched_user?.hasSchedual
          ? "subTotal borderBlue"
          : "subTotal borderOrange"
      }
    >
      <h4>ORDER SUMMARY</h4>
      <div className="subTotal_details">
        <div className="total-parts">
          <span>TOTAL PARTS</span>
          <span> {items_length} </span>
        </div>
        <div className="shipping-cost">
          <span>SHIPPING</span>
          <span>$ {shipmentCost} </span>
        </div>
        <fieldset className="fieldset_shippment">
          <legend>Shipping</legend>
          <label htmlFor="pickup" className="label">
            <input
              type="radio"
              value="pickup"
              id="pickup"
              name="shippingType"
              onChange={handleChangeShipping}
            />
            Pickup
          </label>

          <label htmlFor="delivery">
            <input
              type="radio"
              id="delivery"
              value="delivery"
              name="shippingType"
              onChange={handleChangeShipping}
            />
            Delivery
          </label>
        </fieldset>
        <div className="schedualed">
          {/* if has schdual -- > Label: Replace the schedual  */}
        </div>

        <div className="total-cost">
          <span>TOTAL</span>
          <span>$ {total_cost.toFixed(2)}</span>
        </div>
        <button
          className={
            radioValue === "" || Object.keys(basket).length === 0
              ? "disabled-schedual"
              : "update-schedual-btn"
          }
          disabled={radioValue === ""}
          onClick={handle_Schedual_Update}
        >
          update schedule
        </button>
      </div>
    </section>
  );
}

export default SchedualSubTotal;
