import React from "react";
import LabelStatus from "../SubComponents/LabelStatus";

function PosUserInfo({
  CUSTOMER_NUMBER,
  TAXID,
  COMPANY,
  PRINCING_CODE,
  // status,
}) {
  return (
    <section className="PosUserInfo-wraper">
      <ul>
        <li>
          <label>Customer Number:</label>
          <span> {CUSTOMER_NUMBER}</span>
        </li>
        <li>
          <label>Tax id:</label>
          <span>{TAXID}</span>
        </li>

        <li>
          <label>company:</label>
          <span>{COMPANY}</span>
        </li>

        <li>
          <label>pricing code:</label>
          <span>{PRINCING_CODE}</span>
        </li>
        {/* <li>
          <label>status:</label>
          <LabelStatus
            statusValue={status}
            setOfValues={{ 0: "Pending", 1: "Active", "-1": "Disabled" }}
          />
        </li> */}
      </ul>
    </section>
  );
}

export default PosUserInfo;
