export const ProductPOSStructure = () => {
  return {
    Item_Number: "",
    Description: "",
    Category: "",
    Sub_Category: "",
    Case_Pack: "",
    PriceA: "",
    PriceB: "",
    PriceC: "",
    PriceD: "",
    Retail: "",
    isBestDeal: "",
    Quantity: "",
    UPC_Code: "",
    Individual_UPC: "",
    images: "",
  };
};

export const ProductFirebaseStructure = () => {
  return {
    Item_Number: "",
    Description: "",
    Category: "",
    Sub_Category: "",
    Case_Pack: "",
    PriceA: "",
    PriceB: "",
    PriceC: "",
    PriceD: "",
    Retail: "",
    isBestDeal: "",
    Quantity: "",
    UPC_Code: "",
    Individual_UPC: "",
    images: "",

    countRequestedProducts: 0,
    timestamp: "",
  };
};

export const checkingProductBeforeSync = (excelRowItem) => {
  return {
    Item_Number: checkInteger(excelRowItem.Item_Number),
    Description: checkString(excelRowItem.Description),
    Category: checkString(excelRowItem.Category),
    Sub_Category: checkString(excelRowItem.Sub_Category),
    Case_Pack: checkInteger(excelRowItem.Case_Pack),
    PriceA: checkDecimal(excelRowItem.PriceA),
    PriceB: checkDecimal(excelRowItem.PriceB),
    PriceC: checkDecimal(excelRowItem.PriceC),
    PriceD: checkDecimal(excelRowItem.PriceD),
    Retail: checkDecimal(excelRowItem.Retail),
    isBestDeal: checkInteger(excelRowItem.isBestDeal),
    Quantity: checkInteger(excelRowItem.Quantity),
    UPC_Code: checkString(excelRowItem.UPC_Code),
    Individual_UPC: checkString(excelRowItem.Individual_UPC),
    // images: excelRowItem.images,

    countRequestedProducts: checkInteger(excelRowItem.countRequestedProducts),
    // timestamp: excelRowItem.,
  };
};
const checkDecimal = (value) => {
  if (!value) return 0;
  return parseFloat(value);
};
const checkInteger = (value) => {
  if (!value) return 0;
  return parseInt(value);
};
const checkString = (value) => {
  if (!value) return "";
  return value;
};
export const ProductConstruct = ({
  Item_Number,
  Description,
  Category,
  Sub_Category,
  Case_Pack,
  PriceA,
  PriceB,
  PriceC,
  PriceD,
  Retail,
  isBestDeal,
  Quantity,
  UPC_Code,
  Individual_UPC,
  images,
  countRequestedProducts,
  timestamp,
}) => {
  this.Item_Number = parseInt(Item_Number);
  this.Description = Description;
  this.Category = Category;
  this.Sub_Category = Sub_Category;
  this.Case_Pack = parseInt(Case_Pack);
  this.PriceA = parseFloat(PriceA);
  this.PriceB = parseFloat(PriceB);
  this.PriceC = parseFloat(PriceC);
  this.PriceD = parseFloat(PriceD);
  this.Retail = parseFloat(Retail);
  this.isBestDeal = isBestDeal;
  this.Quantity = parseInt(Quantity);
  this.UPC_Code = UPC_Code;
  this.Individual_UPC = Individual_UPC;
  this.images = images;
  this.countRequestedProducts = parseInt(countRequestedProducts);
  this.timestamp = timestamp;
};

export const toDateTime = (secs) => {
  var t = new Date(Date.UTC(1970, 0, 1)); // Epoch
  t.setSeconds(secs);
  return t;
};
