import React, { useState } from "react";
import Images from "../../config/Images";

function PictureWraper({ imgSrc }) {
  const [loaded, setLoaded] = useState(false);
  return (
    <div className="picture-wrapper">
      {loaded ? null : <img src={Images.imgDefault} alt={Images.imgDefault} />}

      <img
        style={loaded ? {} : { display: "none" }}
        src={imgSrc}
        onLoad={() => setLoaded(true)}
      />
    </div>
  );
}

export default PictureWraper;
