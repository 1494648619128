import React, { useCallback, useEffect, useState } from "react";
import { serverTimestamp, updateDoc } from "firebase/firestore";
import { useStateValue } from "../../config/StateProvider";
import { collection, addDoc, doc, setDoc } from "firebase/firestore";
import { db } from "../../config/firebaseConfig";
import { useNavigate } from "react-router";
// import Paypal from "../Paypal/Paypal";
import CheckoutPopup from "./CheckoutPopup";
import {
  placeOrder,
  sendEmailCashonDelivery,
  updateSchuale,
} from "../../writeData/FirebaseFunc";
import EventHandler from "../SubComponents/EventHandler";
function SubTotal() {
  const [{ user, shippingCost, basket }, dispatch] = useStateValue();
  const [items_length, setItem_length] = useState(0);
  const [total_cost, setTotalCost] = useState(0);
  const history = useNavigate();
  const [radioValue, setRadioValue] = useState("");
  const [shipmentCost, setShipmentCost] = useState(0);
  const [popUp, setPopUp] = useState("none");
  const [payPalPopUp, setPayPalPopUp] = useState("none");
  const [schedualedMonthly, setSchedualedMonthly] = useState(false);
  const [placeNow, setPlaceNow] = useState(true);
  //payPalPopUp
  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  const regexPhone = /^[0-9\b]+$/;
  const [userRequest, setUserRequest] = useState({
    phone: "",
    email: "",
    location: "",
    street: "",
    note:""
  });
  const onChangeValue = (e) => {
    changeProperyValue("email", user.email);
    setRadioValue(e.target.value);

    if (e.target.value === "pickup") {
      setShipmentCost(0);
    } else {
      setShipmentCost(shippingCost);
    }
  };
  const cleanBasket = () => {
    dispatch({
      type: "BASKET_UPDATE",
      basket: {},
      setDoc: true,
      useruid: user.uid,
    });
    history("/");
  };
  const CashOnDeliveryPopUp = () => {
    setPopUp("flex");
  };
  const placeSchedual = async () => {
    ClosePopUp();

    const schedualOrder = {
      posuser: user.switched_user,
      products: basket,
      total: parseFloat(total_cost).toFixed(2),
      scheduleuserid: user.switched_user.Customer_Number,
      placedByuid: user.uid,
      status: 1,
      shippingType: radioValue,
    };
    // const timeNow = new Date().getTime();
    // const requestid = `${user.switched_user.Customer_Number}_${timeNow}`;
    schedualOrder.timestamp = serverTimestamp();
    // schedualOrder.month = Timestamp.toDate();
    schedualOrder.updatedAt = serverTimestamp();
    let month = new Date().getMonth() + 1;
    // console.log("month", month);
    if (month == 12) {
      month = 0;
    }
    schedualOrder.month = month;
    // console.log("schedualOrder", schedualOrder);
    const doc_admin = doc(
      db,
      "users",
      user.uid,
      "schedualedorders",
      user.switched_user.Customer_Number
    );
    await setDoc(doc_admin, schedualOrder).then(() => {
      // console.log("chedualed order succesfully done :) ");
      // show in cart header the schedualed items

      const newBasket = {}; //{}
      dispatch({
        type: "BASKET_UPDATE",
        basket: newBasket,
        setDoc: true,
        useruid: user.uid,
      });
    });
  };
  const createOrder = () => {
    // const wanted_items = basket.filter((obj, index) => obj.selected === true);
    const orderRequest = Object.assign({}, { products: basket });
    const timeNow = new Date().getTime();
    // if role === 1 -> admin requestid = admin_uid_timestamp
    // if role ===3 -> customer  requestid = admin_uid_timestamp
    const requestid = `${user.uid}_${timeNow}`;

    let total_cost = Object.values(basket).reduce(
      (prevCost, next_Object) =>
        prevCost + next_Object.quantity * next_Object.Price,
      shipmentCost
    );
    orderRequest.posuser = user.posUserInfo ? user.posUserInfo : null;
    orderRequest.timestamp = serverTimestamp(); //
    orderRequest.requestid = requestid; //
    orderRequest.total = total_cost.toFixed(2); //
    // orderRequest.status = 0;
    // orderRequest.paymentType = "Cash";
    orderRequest.shippingType = radioValue; //
    orderRequest.note = userRequest.note
    orderRequest.user = {
      email: userRequest.email,
      uid: user.uid,
      location: userRequest.location,
      name: userRequest.name,
      phone: userRequest.phone,

      // role: user.role,
    };
    // place order then send email and clear basket
    console.log('order',orderRequest)
    placeOrder(requestid, orderRequest).then(() => {
      sendEmailCashonDelivery(
        basket,
        user.email,
        total_cost,
        user,
        radioValue,
        userRequest
      );
      cleanBasket();
    });
  };
  const changeProperyValue = (property, value) => {
    // console.log('newwww',property,value)
    userRequest[property] = value;
    const new_request1 = Object.assign({}, userRequest);

    setUserRequest(new_request1);
  };
  const validateUserRequestFields = () => {
    // console.log("chek", userRequest);
    let problem = true;
    if (radioValue == "pickup") {
      userRequest.location = "";
      userRequest.street = "";
      changeProperyValue("locationError", "");
      changeProperyValue("streetError", "");
      problem = false;
    } else {
      //delivery
      //return problem if street or location is null
      if (userRequest.location == "" || userRequest.street == "") {
        problem = true;
      }
      if (userRequest.location == "") {
        changeProperyValue("locationError", "!Required");
      } else {
        changeProperyValue("locationError", "");
      }
      if (userRequest.street == "") {
        changeProperyValue("streetError", "!Required");
      } else {
        changeProperyValue("streetError", "");
      }
      if (userRequest.street !== "" && userRequest.location !== "") {
        // console.log("ffffffffffff", userRequest);
        problem = false;
      }
    }

    // console.log("problem", problem);
    return problem;
  };
  const handleCheckout = (e) => {
    if (!validateUserRequestFields()) {
      createOrder(user);
      ClosePopUp();
      console.log('pay',)
    }
    // if (schedualedMonthly) {
    //   // sub collection : schedualedorders -
    //   // document reference : posUserInfo.Customer_Number

    //   // if  no schedual or status ===1
    //   // if status  ===1 repplace onl 3 fields
    //   placeSchedual();
    // }
  };
  const ClosePopUp = () => {
    setPopUp("none");
  };
  const ClosePayPalPopUp = () => {
    setPayPalPopUp("none");
  };
  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setPopUp("none");
    }
  }, []);
  const basketLength = () => {
    let temp_totals = Object.values(basket).reduce(
      (prevTotal, nextElement) => prevTotal + nextElement.quantity,
      0
    );
    return temp_totals;
  };
  const changeTotalCost = (initialCost) => {
    let temp_total_cost = Object.values(basket).reduce(
      (prevCost, next_Object) =>
        prevCost + next_Object.quantity * next_Object.Price,
      initialCost
    );
    return temp_total_cost;
  };

  useEffect(() => {
    // changeProperyValue("email", userRequest.email);

    // console.log("userRequest", userRequest);

    document.addEventListener("keydown", escFunction);
    return () => {
      document.removeEventListener("keydown", escFunction);
    };
  }, [escFunction]);
  useEffect(() => {
    // console.log("user", user);
    if (user) {
      // console.log("user", user);
      let tele = "";
      if (user.posUserInfo?.Phone) {
        tele = user.posUserInfo?.Phone;
      }
      setUserRequest({
        name: user.name,
        email: user.email,
        phone: tele,
        location: "",
        passwordError: "",
        locationError: "",
        street: "",
      });
    }
  }, [user]);
  useEffect(() => {
    // console.log("cart bakset", basket);
    if (radioValue == "pickup") {
      // setTotalCost(temp_total_cost < 0 ? 0 : temp_total_cost);
      setTotalCost(changeTotalCost(0));
    } else {
      // setTotalCost(
      //   temp_total_cost < shippingCost ? shippingCost : temp_total_cost
      // );
      setTotalCost(changeTotalCost(shipmentCost));
    }
    setItem_length(basketLength());
  }, [{ basket, radioValue }]);
  return (
    <section
      className={
        user.switched_user?.hasSchedual
          ? "subTotal borderBlue"
          : "subTotal borderOrange"
      }
    >
      <h4>ORDER SUMMARY</h4>
      <div className="subTotal_details">
        <div className="total-parts">
          <span>TOTAL PARTS</span>
          {/*<span> {items_length} </span>*/}
          <span>{Object.keys(basket).length} </span>
        </div>
        <div className="shipping-cost">
          <span>SHIPPING</span>
          <span>$ {shipmentCost} </span>
        </div>
        <fieldset className="fieldset_shippment">
          <legend>Shipping</legend>
          <label htmlFor="pickup" className="label">
            <input
              type="radio"
              value="pickup"
              id="pickup"
              name="shippingType"
              onChange={onChangeValue}
            />
            Pickup
          </label>

          <label htmlFor="delivery">
            <input
              type="radio"
              id="delivery"
              value="delivery"
              name="shippingType"
              onChange={onChangeValue}
            />
            Delivery
          </label>
        </fieldset>
        {/* {user.role === 2 ? (
          <>
            <div className="schedualed">

              <label htmlFor="chedualed" className="label">
                <input
                  type="checkbox"
                  id="chedualed"
                  name="chedualed"
                  value={schedualedMonthly}
                  onChange={() => setSchedualedMonthly(!schedualedMonthly)}
                />
                monthly chedualed
              </label>
            </div>
          </>
        ) : null} */}

        <div className="total-cost">
          <span>TOTAL</span>
          <span>$ {total_cost.toFixed(2)}</span>
        </div>
        <div className="subTotal_Buy">
          <button
            disabled={items_length <= 0 || radioValue == "" ? true : false}
            className={
              items_length <= 0 || radioValue == ""
                ? "buy_btn disabled_btn"
                : "buy_btn enabled_btn"
            }
            onClick={() => CashOnDeliveryPopUp()}
          >
            checkout
          </button>
        </div>
      </div>

      <CheckoutPopup
        popUp={popUp}
        ClosePopUp={ClosePopUp}
        userRequest={userRequest}
        changeProperyValue={changeProperyValue}
        radioValue={radioValue}
        handleCheckout={handleCheckout}
        total_cost={total_cost}
        schedualedMonthly={schedualedMonthly}
        validateUserRequestFields={validateUserRequestFields}
      />
    </section>
  );
}

export default SubTotal;
