import { faCartPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useStateValue } from "../../config/StateProvider";
import ProductInfo from "../ProductInfo";
import QtyInput from "../SubComponents/QtyInput";
function Product({ product }) {
  const [{ productToAdd, addAction, user }, dispatch] = useStateValue();
  const [quantity, setQuantity] = useState(1);
  const navigate = useNavigate();
  const ChangeQuantity = (qty) => {
    // console.log("qty", qty);
    setQuantity(qty);
    product.quantity = qty;
    // product.selected = true;

    dispatch({
      type: "PRODUCTS_TO_ADD",
      item: product,
    });
  };
  useEffect(() => {
    const index = productToAdd.findIndex(
      (basketItem) => basketItem.Item_Number === product.Item_Number
    );

    if (index >= 0) {
      product.quantity = productToAdd[index].quantity;
      setQuantity(productToAdd[index].quantity.toString());
    } else {
      setQuantity("");
    }
  }, [product]);

  useEffect(() => {
    if (productToAdd.length <= 0) {
      let e = "";
      setQuantity(e);
    }
  }, [productToAdd]);

  const addToCart = () => {
    // console.log("qty", quantity);
    if (user == null) {
      alert("Login to add to your cart");
      navigate("/login");
    } else if (quantity != "") {
      // console.log("Add_To_Basket", product);
      product.quantity = quantity;
      // console.log("addToCart");
      dispatch({
        type: "Add_To_Basket",
        item: product,
        event: "addProduct",
        useruid: user.uid,
      });
      // dispatch({
      //   type: "REMOVE_PRODUCTS_TO_ADD",
      //   item: product,
      // });
      // let e = "";
      // setQuantity(e);
      /*  updateDoc(userDoc, {
      basket: basket
    });*/
    }
  };
  const handleDecrementQty = () => {
    if (quantity == "" || quantity == 1 || quantity == 0) return;
    setQuantity((quantity) => --quantity);
  };
  const handleIncrementQty = () => {
    // console.log("quantity", quantity);

    setQuantity((quantity) => ++quantity);
  };
  return (
    <li>
      <ProductInfo product={product} user={user}>
        <div className="quantity">
          <QtyInput
            quantity={quantity}
            handleIncrementQty={handleIncrementQty}
            handleDecrementQty={handleDecrementQty}
            handeQuantityInput={ChangeQuantity}
          />
          <FontAwesomeIcon icon={faCartPlus} onClick={() => addToCart()} />
        </div>
      </ProductInfo>
    </li>
  );
}

export default Product;
