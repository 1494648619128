import { lazy } from "react";
//client
export const Home = lazy(() => import("../components/Home/Home"));
// export const Login = lazy(() => import("../components/Authentication/Login"));
// export const RegisterEmail = lazy(() =>
//   import("../components/Authentication/RegisterEmail")
// );
export const Login = lazy(() => import("../components/auth/Login"));
export const RegisterEmail = lazy(() => import("../components/auth/Register"));
export const Exit = lazy(() => import("../components/Exit/Exit"));
export const ResetPassword = lazy(() =>
  import("../components/auth/ResetPassword")
);
export const Users = lazy(() => import("../components/Users"));
export const Stock = lazy(() => import("../components/Stock"));
export const ImportCSV = lazy(() => import("../components/Stock/import"));
export const Orders = lazy(() => import("../components/Orders"));
export const Scheduals = lazy(() => import("../components/Scheduals"));
export const Settings = lazy(() => import("../components/Settings"));
export const PolicyUrl = lazy(() =>
  import("../components/SubComponents/PolicyUrl")
);
