import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { useStateValue } from "./config/StateProvider";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
// import "./assets/css/style.css";
import "./assets/dist/styles.css";
import AuthController from "./components/Authentication/AuthController";
import AppRoutes from "./AppRoutes";
import FetchEssentialData from "./getData/FetchEssentialData";
import BottomMenu from "./components/BottomMenu";
function App() {
  // const auth = getAuth();
  const [{ user, basket }, dispatch] = useStateValue();
  // const history = useNavigate();

  useEffect(() => {
    document.title = "Phoenix";
  }, []);
  return (
    <BrowserRouter>
      <Header />
      <FetchEssentialData />
      <AuthController />
      <AppRoutes />
      <Footer />
      <BottomMenu />
    </BrowserRouter>
  );
}

export default App;
