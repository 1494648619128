import React, { useEffect, useState } from "react";

import { useStateValue } from "../../config/StateProvider";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { Link } from "react-router-dom";
import EventHandler from "../SubComponents/EventHandler";
import { FaAngleDown, FaAngleUp, FaTrash } from "react-icons/fa";
import QtyInput from "../SubComponents/QtyInput";
function ProductCart({ item }) {
  const [{ basket, user }, dispatch] = useStateValue();

  const handleRemoveItemFromCart = () => {
    EventHandler(
      () => {
        dispatch({
          type: "Remove_From_Basket",
          basket: basket,
          Item_Number: item.Item_Number,
          useruid: user.uid,
        });
      },
      "Remove Item",
      "",
      "Yes"
    );
  };

  const handleAddQuantity = (qty_to_add) => {
    if (item.quantity === 1 && qty_to_add < 0) {
    } else {
      // add qty
      // let item_index = basket.findIndex(
      //   (obj, idx) => obj.Item_Number === item.Item_Number
      // );
      const newCart = Object.assign({}, basket);
      newCart[item.Item_Number].quantity = item.quantity + qty_to_add;
      dispatch({
        type: "BASKET_UPDATE",
        basket: newCart,
      });
    }
  };
  const handleIncrementQty = () => {
    const newCart = Object.assign({}, basket);
    newCart[item.Item_Number].quantity = item.quantity + 1;
    dispatch({
      type: "BASKET_UPDATE",
      basket: newCart,
      useruid: user.uid,
    });
  };
  const handleDecrementQty = () => {
    if (item.quantity <= 1 || item.quantity == 0 || item.quantity == "") return;
    const newCart = Object.assign({}, basket);
    newCart[item.Item_Number].quantity = item.quantity - 1;
    dispatch({
      type: "BASKET_UPDATE",
      basket: newCart,
    });
  };
  const handleChangeQuantity = (qtyvalue) => {
    const newCart = Object.assign({}, basket);
    newCart[item.Item_Number].quantity = qtyvalue;

    dispatch({
      type: "BASKET_UPDATE",
      basket: newCart,
    });
  };
  return (
    <section className="product-cart-section">
      <div className="cart-img-wraper">
        <img src={item.images} alt="image" />
      </div>
      <section className="product-information">
        <div className="product-description">
          <abbr title={item.Description}>{item.Description}</abbr>
          <DeleteForeverIcon
            className="trash"
            onClick={() => handleRemoveItemFromCart()}
          />
        </div>

        <div className="item_quantity">
          <QtyInput
            quantity={item.quantity}
            handleDecrementQty={handleDecrementQty}
            handleIncrementQty={handleIncrementQty}
            handeQuantityInput={handleChangeQuantity}
          />
        </div>
        <div className="product-totalPrice">
          <small>$ {(item.Price * item.quantity).toFixed(2)}</small>
        </div>
        <div className="deleteIcon">
          <FaTrash
            className="trash"
            onClick={() => handleRemoveItemFromCart()}
          />
        </div>
      </section>
    </section>
  );
}

export default ProductCart;
