import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import path from "../../config/path";
import { useStateValue } from "../../config/StateProvider";

function Navs({ activePage }) {
  const [{ user }] = useStateValue();
  return (
    <nav>
      <ul>
        <li>
          <Link
            to={path.home}
            className={activePage === path.home ? "active-page" : ""}
          >
            Home
          </Link>
        </li>
        <li>
          <Link
            to={path.about}
            className={activePage === path.about ? "active-page" : ""}
          >
            About
          </Link>
        </li>
        <li>
          <Link
            to={path.contact}
            className={activePage === path.contact ? "active-page" : ""}
          >
            Contact us
          </Link>
        </li>
        {user && user?.role === 2 ? (
          <>
            <li>
              <Link
                to={path.users}
                className={activePage === path.users ? "active-page" : ""}
              >
                Users
              </Link>
            </li>
            <li>
              <Link
                to={path.stock}
                className={activePage === path.stock ? "active-page" : ""}
              >
                Stock
              </Link>
            </li>
            <li>
              <Link
                to={path.orders}
                className={activePage === path.orders ? "active-page" : ""}
              >
                Orders
              </Link>
            </li>
            {/* <li>
              <Link
                to={path.notes}
                className={activePage === path.notes ? "active-page" : ""}
              >
                Notes
              </Link>
            </li> */}
            {/* <li>
              <Link
                to={path.scheduals}
                className={activePage === path.scheduals ? "active-page" : ""}
              >
                Schedule
              </Link>
            </li> */}
          </>
        ) : null}
      </ul>
    </nav>
  );
}

export default Navs;
