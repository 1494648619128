// import homeTop from "../assets/images/home-top.jpg";
import homeTop from "../assets/svg/sv1.svg";

import cart from "../assets/svg/Icon metro-cart.svg";
import searchIcon from "../assets/svg/search-icon.svg";
import clearFilters from "../assets/svg/clearFilters.svg";
import verticalDots from "../assets/svg/vertical dots.svg";
// import logoWhite from "../assets/images/Phoenix Logo white-01.png";
import logoWhite from "../assets/images/logos/Phoenix Logo white-01@2x.png";
import imgDefault from "../assets/images/default-thumbnail.png";
import logo from "../assets/images/logo.png";
import logo2 from "../assets/images/logos/logo.png";
import crossIcon from "../assets/svg/cross.svg";
// import createAccountIcon from "../assets/svg/create-account.svg";
import loginIcon from "../assets/images/Computer login-amico@2x.png";
import boyIcon from "../assets/images/image@2x.png";
import categories1 from "../assets/images/categories1@2x.png";

import triangleSpecialOffer from "../assets/images/triangle_special_offer.png";
import circleSpecialOffer from "../assets/images/circle-special-offer.png";
import special_offer from "../assets/images/special_offer.png";
import special_offers2 from "../assets/images/special_offers2.png";
import home_picture_2 from "../assets/images/chocolates.png";
import home_picture_1 from "../assets/images/kitkat-snickers.png";
import home_slider1 from "../assets/images/home-slider-images/1.jpg";
import home_slider2 from "../assets/images/home-slider-images/2.jpg";
import home_slider3 from "../assets/images/home-slider-images/3.jpg";
import streetMapIcon from "../assets/svg/simple-openstreetmap.svg";
import directionsIcon from "../assets/svg/directions.svg";
import ideasLogo from "../assets/logo/logo version-13.svg";
import ideasWhiteLogo from "../assets/logo/logo white version-20.svg";
import wallpaper1 from "../assets/images/wp3537557.webp";

const Images = {
  imgDefault: imgDefault,
  homeTop: homeTop,
  logoWhite: logoWhite,
  logo: logo,
  logo2: logo2,
  cart: cart,
  clearFilters: clearFilters,
  // bigSaleIcon: bigSaleIcon,
  home_picture_1: home_picture_1,
  home_picture_2: home_picture_2,
  triangleSpecialOffer: triangleSpecialOffer,
  circleSpecialOffer: circleSpecialOffer,
  special_offer: special_offer,
  special_offers2: special_offers2,
  home_slider1: home_slider1,
  home_slider2: home_slider2,
  home_slider3: home_slider3,
  searchIcon: searchIcon,
  verticalDots: verticalDots,
  crossIcon: crossIcon,
  // createAccountIcon: createAccountIcon,
  loginIcon: loginIcon,
  boyIcon: boyIcon,
  directionsIcon: directionsIcon,
  streetMapIcon: streetMapIcon,
  categories1: categories1,

  ideasLogo: ideasLogo,
  ideasWhiteLogo: ideasWhiteLogo,
  wallpaper1: wallpaper1,
};

export default Images;
