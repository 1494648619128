import React from "react";
import Moment from "react-moment";
function DateTimeStamp({ timestamp }) {
  const toDateTime = (secs) => {
    var t = new Date(Date.UTC(1970, 0, 1)); // Epoch
    t.setSeconds(secs);
    return t;
  };
  return (
    <div className="timestamp-wrapper">
      <div className="timestamp-date">
        <Moment parse="YYYY-MM-DD HH:mm" locale format="ddd DD-MMM-yyy">
          {toDateTime(timestamp._seconds)}
        </Moment>
      </div>
      <div className="timestamp-time">
        <strong> At </strong>
        <Moment parse="YYYY-MM-DD HH:mm" locale format="hh:mm">
          {toDateTime(timestamp._seconds)}
        </Moment>
      </div>
    </div>
  );
}

export default DateTimeStamp;
