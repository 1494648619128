import React from "react";

function LabelStatus({ statusValue, setOfValues }) {
  const styledObjed = () => {
    let styles = {};
    switch (statusValue) {
      case 0:
        styles = {
          border: "1px solid #fdb827",
          backgroundColor: "#fdb827",
        };
        break;
      case 1:
        styles = {
          border: "1px solid green",
          backgroundColor: "hsl(120, 100%, 30%)",
        };
        break;
      case -1:
        styles = {
          border: "1px solid red",
          backgroundColor: "red",
        };
        break;
      default:
        styles = {
          border: "1px solid gray",
          backgroundColor: "gray",
        };
        break;
    }
    return styles;
  };

  return (
    <label className="label-status" style={styledObjed()}>
      {setOfValues[statusValue]}
    </label>
  );
}

export default LabelStatus;
