const path = {
  dashboard: "/dashboard",
  home: "/",
  about: "/about",
  login: "/login",
  register: "/register",
  order: "/order",
  productList: "/all",
  detail: "/detail",
  contact: "/contact",
  exit: "/exit",
  resetPass: "/resetPass",
  users: "/users",
  productTable: "/productTable",
  stock: "/stock",
  orders: "/orders",
  request:"/request:requestid",
  settings: "/settings",
  scheduals: "/scheduals",
  importcsv: "/importcsv",
  // notes: "/notes",
};

export default path;
