import React from "react";
import { useStateValue } from "../../config/StateProvider";

function TopHeader() {
  const [{ user }, dispatch] = useStateValue();

  return (
    <div className="first-header">
      {user ? (
        <>
          <span> {user?.switched_user?.Company}</span>
          <span> {user?.switched_user?.TAXID}</span>
        </>
      ) : null}
    </div>
  );
}

export default TopHeader;
