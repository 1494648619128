import React from "react";

function SearchIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.872"
      height="13.872"
      viewBox="0 0 13.872 13.872"
    >
      <path
        id="Path_9200"
        data-name="Path 9200"
        d="M15.552,14.371,12.471,11.3A5.859,5.859,0,1,0,11.3,12.471l3.074,3.081a.836.836,0,0,0,1.181,0h0a.836.836,0,0,0,0-1.181Zm-7.745-2.4a4.145,4.145,0,1,1,4.145-4.145,4.145,4.145,0,0,1-4.145,4.145Z"
        transform="translate(-1.924 -1.924)"
        fill="hsl(20, 90%, 51%)"
      />
    </svg>
  );
}

export default SearchIcon;
