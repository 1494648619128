import React from "react";
import { Navigate } from "react-router-dom";

function PrivateRoute({ user, children }) {
  // console.log("PrivateRoute", user);
  if (!user) {
    return <Navigate to="/" replace />;
  } else {
    return children;
  }
}

export default PrivateRoute;
