import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { config } from "./config";
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const app = initializeApp(config.firebaseConfig);
const db = getFirestore();
const auth = getAuth();
const storage = getStorage();
export { db, auth, storage };
