import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import path from "./config/path";
import ProductList from "./components/ProductList";
import ProductDetails from "./components/ProductDetails/ProductDetails";
import {
  Home,
  Login,
  RegisterEmail,
  Exit,
  ResetPassword,
  Users,
  Stock,
  Orders,
  Scheduals,
  ImportCSV,
  Settings,
  PolicyUrl,
} from "./config/Lazy";
import Cart from "./components/Cart/Cart";
import About from "./components/About/About";
import Contact from "./components/Contact/Contact";
import PrivateRoute from "./config/PrivateRoute";
import { useStateValue } from "./config/StateProvider";
import SingleOrder from "./components/Orders/SingleOrder";

function AppRoutes() {
  const [{ user }] = useStateValue();
  return (
    <Suspense fallback={<p>Loading ...</p>}>
      <Routes>
        <Route path={path.home} element={<Home />} />
        <Route path={path.about} element={<About />} />
        <Route path={path.contact} element={<Contact />} />

        <Route path={path.resetPass} element={<ResetPassword />} />
        <Route path={path.productList} element={<ProductList />} />
        <Route
          path={path.productList + "/page/:page"}
          element={<ProductList />}
        />
        <Route
          path={path.productList + "/discounted"}
          exact
          element={<ProductList />}
        />
        <Route
          path={path.productList + "/type/:type/:myString"}
          element={<ProductList />}
        />
        <Route
          path={path.productList + "/type/:type/:myString/:subCategory"}
          exact
          element={<ProductList />}
        />
        <Route
          path={path.productList + "/discounted/page/:page"}
          exact
          element={<ProductList />}
        />
        <Route
          path={path.productList + "/type/:type/:myString/page/:page"}
          exact
          element={<ProductList />}
        />
        <Route
          path={
            path.productList + "/type/:type/:myString/:subCategory/page/:page"
          }
          element={<ProductList />}
        />

        <Route path={path.login} element={<Login />} />
        <Route path={path.exit} element={<Exit />} />
        <Route path={path.register} element={<RegisterEmail />} />
        <Route path={path.order} element={<Cart />} />
        {/* <Route path={path.detail + "/:id/:name"} element={<ProductDetails />} /> */}
        <Route path={path.detail + "/:id"} element={<ProductDetails />} />
        <Route
          path={path.users}
          element={
            <PrivateRoute user={user}>
              <Users />
            </PrivateRoute>
          }
        />
        <Route
          path={path.productTable}
          element={
            <PrivateRoute user={user}>
              <Users />
            </PrivateRoute>
          }
        />
        {/* <Route
          path={path.notes}
          element={
            <PrivateRoute user={user}>
              <Notes />
            </PrivateRoute>
          }
        /> */}
        <Route
          path={path.stock}
          element={
            <PrivateRoute user={user}>
              <Stock />
            </PrivateRoute>
          }
        />
        <Route
          path={path.orders}
          element={
            <PrivateRoute user={user}>
              <Orders />
            </PrivateRoute>
          }
        />
        <Route path={path.request} element={<SingleOrder />} />
        {/* <Route
          path={path.scheduals}
          element={
            <PrivateRoute user={user}>
              <Scheduals />
            </PrivateRoute>
          }
        /> */}
        <Route
          path={path.importcsv}
          element={
            <PrivateRoute user={user}>
              <ImportCSV />
            </PrivateRoute>
          }
        />
        <Route
          path={path.settings}
          element={
            <PrivateRoute user={user}>
              <Settings />
            </PrivateRoute>
          }
        />
        <Route path={path.policy} element={<PolicyUrl />} />
        {/* <Route path={"/paypal"} exact component={Paypal} /> */}
      </Routes>
    </Suspense>
  );
}

export default AppRoutes;
