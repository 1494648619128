import React, { createContext, useReducer, useContext } from "react";

export const StateContext = createContext();

//reducer is the function
export const StateProvider = ({ reducer, initialState, children }) => (
  <>
    <StateContext.Provider
      value={useReducer(reducer, initialState)}
      displayName="Values"
    >
      {children}
    </StateContext.Provider>
  </>
);

export const useStateValue = () => useContext(StateContext);
