import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchSingleRequest } from "../../getData/Elastic_search";
import ProductContent from "./ProductContent";
import TableView from "../SubComponents/Tables/TableView";
import PictureWraper from "../SubComponents/PictureWraper";
import DateTimeStamp from "../SubComponents/DateTimeStamp";
function SingleOrder() {
  const [data, setData] = useState(null);
  const params = useParams();
  console.log("params", params);

  useEffect(() => {
    if (!params.requestid) return;
    let temp = {};
    const getSingleUser = async () => {
      await fetchSingleRequest(params.requestid).then((res) => {
        if (res.status) {
          temp = res.data.hits.hits[0]._source;
        }
      });
      console.log("temp", temp);
      setData(temp);
    };
    getSingleUser();

    return () => {};
  }, []);

  const columns = [
    {
      field: "index",
      headerName: "ID",
      type: "number",
      // hide: true,
      minMaxwidth: "100px",

      thStyle: "th-id",
      renderCell: (params) => <>{params.index + 1}</>,
    },
    {
      field: "Item_Number",
      headerName: "Item_Nb",
      type: "number",
      // hide: true,
      minMaxwidth: "100px",

      thStyle: "th-id",
    },
    // {
    //   field: "images",
    //   headerName: "Picture",
    //   // type: "number",
    //   // hide: true,
    //   minMaxwidth: "180px",
    //   tdCellStyle: "td-picture",
    //   thStyle: "th-picture",
    //   // renderHeader: () => (
    //   //   <TH_Picture
    //   //     dataFilters={dataFilters}
    //   //     handleFilterChange={handleFilterChange}
    //   //   />
    //   // ),
    //   renderCell: (params) => <PictureWraper imgSrc={params.images} />,
    // },

    {
      field: "Description",
      headerName: "Description",
      type: "number",
      // hide: true,
      minMaxwidth: "100px",
      tdCellStyle: "td-id",
      thStyle: "th-id",
    },
    {
      field: "Price",
      headerName: "Price",
      type: "number",
      // hide: true,
      minMaxwidth: "100px",
      tdCellStyle: "td-id",
      thStyle: "th-id",
      renderCell: (params) => <>$ {params.Price}</>,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      type: "number",
      // hide: true,
      minMaxwidth: "100px",
      tdCellStyle: "td-id",
      thStyle: "th-id",
    },
  ];

  return (
    <main className="single-request">
      {data ? (
        <>
          <section className="request-header">
            <ul>
              <li>
                number of items:{" "}
                <span>{Object.keys(data.products).length}</span>
              </li>
              <li>
                total price: <span>${data.total}</span>
              </li>
              <li>
                ordered at:{" "}
                <span>
                  <DateTimeStamp timestamp={data.timestamp} />
                </span>
              </li>
            </ul>
            <ul>
              <li>
                name: <span>{data.user.name}</span>
              </li>
              <li>
                location: <span>{data.user.location}</span>
              </li>
              <li>
                phone: <span>{data.user.phone}</span>
              </li>
              <li>
                note: <span>{data.note}</span>
              </li>
            </ul>
          </section>

          {/* <section className="requested-items flexWrap">
            {Object.values(data.products).map((item, idx) => (
              <>
                <ProductContent key={idx} product={item} />
              </>
            ))}
          </section> */}
          <div className="table-wraper">
            <TableView
              tableCaption={() => <></>}
              columns={columns}
              data={data?.products ? Object.values(data.products) : []}
              // loading={loading}
            />
          </div>
        </>
      ) : (
        "loading data"
      )}
    </main>
  );
}

export default SingleOrder;
