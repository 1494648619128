import React, { useEffect, useState } from "react";
import {
  FaClock,
  FaCogs,
  FaCubes,
  FaHome,
  FaList,
  FaShoppingBasket,
  FaSignInAlt,
  FaSignOutAlt,
  FaUserEdit,
} from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import path from "../../config/path";
import { useStateValue } from "../../config/StateProvider";
import { handleExist } from "../../Funcs";
import Images from "../../config/Images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
function BottomMenu() {
  const [{ user, showCategories }, dispatch] = useStateValue();
  const [activePage, setActivePage] = useState("");
  const location = useLocation();
  useEffect(() => {
    setActivePage(location.pathname);
  }, [location]);

  return (
    <div className="bottom-menu">
      {user && user.role === 2 ? (
        <ul>
          <li>
            <Link
              to={path.users}
              className={activePage === path.users ? "active-page" : ""}
            >
              <FaUserEdit />
              {/* Users */}
            </Link>
          </li>

          <li>
            <Link
              to={path.stock}
              className={activePage === path.stock ? "active-page" : ""}
            >
              <FaCubes />
              {/* Stock */}
            </Link>
          </li>

          <li>
            <Link
              to={path.orders}
              className={activePage === path.orders ? "active-page" : ""}
            >
              <FaShoppingBasket />
              {/* Orders */}
            </Link>
          </li>

          {/*<li>*/}
          {/*  <Link*/}
          {/*    to={path.scheduals}*/}
          {/*    className={activePage === path.scheduals ? "active-page" : ""}*/}
          {/*  >*/}
          {/*    <FaClock />*/}
          {/*     Scheduals */}
          {/*  </Link>*/}
          {/*</li>*/}
          {/* <li>
            <Link
              to={path.settings}
              className={activePage === path.settings ? "active-page" : ""}
            >
              <FaCogs />
             
            </Link>
          </li> */}
        </ul>
      ) : (
        <ul>
          <li>
            <Link
              to={path.home}
              className={activePage === path.settings ? "active-page" : ""}
            >
              {/* <FaHome /> */}
              <FontAwesomeIcon icon={faHome} />
            </Link>
          </li>
          <li>
            <a
              onClick={() => {
                //show left categories
                // setShow("0");
                dispatch({
                  type: "TOGGLE_SHOW_CATEGORIES",
                  showCategories: "0",
                });
              }}
            >
              <img src={Images.categoriesIcon} alt={Images.homeIcon} />
              <FaList />
            </a>
          </li>
        </ul>
      )}
    </div>
  );
}

export default BottomMenu;
{
  /* <ul className="customer-navs">
          <li>
            <Link
              to={path.home}
              className={activePage === path.home ? "active-page" : ""}
            >
              <FaHome />
              Home
            </Link>
          </li>
          <li>
            <a className="categories-icon">
              <img src={Images.categories1} alt={Images.categories1} />
            </a>
          </li> 
          <li>
            {user ? (
              <a
                onClick={() => {
                  handleExist();
                }}
              >
                <FaSignOutAlt />
                sign out
              </a>
            ) : (
              <Link to={path.login}>
                <FaSignInAlt /> log in
              </Link>
            )}
          </li>
        </ul>
       */
}
