import React, { useState } from "react";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import path from "../../config/path";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faCaretDown } from "@fortawesome/free-solid-svg-icons";

export default function Category({
  parentCategory,
  subCategory,
  event,
  HideNavMenu,
}) {
  const [showChild, setShowChild] = useState("0");
  const [opacity, setOpacity] = useState("0.5");
  const [styledbackgroud, setStyledBackgroud] = useState({});

  const showSubCategory = () => {
    if (showChild == "1000px") {
      setShowChild("0");
      setOpacity("0.5");
      setStyledBackgroud({});
    } else {
      setShowChild("1000px");
      setOpacity("1");
      setStyledBackgroud({
        background: "rgb(242 144 17 / 27%)",
        color: "#1170aa",
      });
    }
  };
  return (
    <li>
      <Link
        // onClick={() => event(true)}
        onClick={HideNavMenu}
        style={styledbackgroud}
        to={path.productList + "/type/category/" + parentCategory}
      >
        <span> {parentCategory}</span>
      </Link>
      {subCategory.length > 0 ? (
        <FontAwesomeIcon
          icon={faAngleDown}
          className="arrowDropdown"
          style={{ opacity: opacity }}
          onClick={() => showSubCategory()}
        />
      ) : (
        <></>
      )}

      <ul style={{ maxHeight: showChild }}>
        {subCategory.map((item, index) => (
          <li key={index}>
            <Link
              // onClick={() => event(true)}
              onClick={HideNavMenu}
              to={
                path.productList +
                "/type/category/" +
                parentCategory +
                "/" +
                item
              }
            >
              {item}
            </Link>
          </li>
        ))}
      </ul>
    </li>
  );
}
