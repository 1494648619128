import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import { checkUserByTaxId, getUserByEmail } from "../../getData/Elastic_search";
import { auth } from "../../config/firebaseConfig";
import { useStateValue } from "../../config/StateProvider";
import path from "../../config/path";

export default function AuthController() {
  const [{ user, basket }, dispatch] = useStateValue();
  const history = useNavigate();

  useEffect(() => {
    // console.log("AuthController", user);
    auth.onAuthStateChanged(async (authUser) => {
      if (authUser) {
        // console.log("authUser", authUser, user);
        if (authUser.emailVerified) {
          // check user taxid in userpos collection

          let myUser;
          authUser.getIdToken().then((token) => {
            // console.log("token", token);
            // console.log(authUser.getIdToken());
            // setToken(token);
          });
          await getUserByEmail(authUser.email).then((result) => {
            // console.log("getUserByEmail", result);
            if (result) {
              result.data.hits.hits.map((hit) => {
                myUser = hit._source;
              });
            }
          });

          if (myUser) {
            // localStorage.setItem("user", JSON.stringify(myUser));
            // console.log("myUser", myUser);
            //saleTaxId

            dispatch({
              type: "SET_USER",
              // user: authUser,
              user: myUser,
            });
            dispatch({
              type: "BASKET_UPDATE",
              basket: myUser.basket,
              setDoc: false,
              fromElastic: true,
            });
            // localStorage.setItem("user", JSON.stringify(myUser));
            if (
              window.location.pathname == "/login" ||
              window.location.pathname == "/register"
            ) {
              history("/");
            }
          } else {
            dispatch({
              type: "SET_USER",
              user: null,
            });
            // localStorage.removeItem("user");
            if (window.location.pathname == "/order") {
              history("/");
            }
          }
        } else {
          dispatch({
            type: "SET_USER",
            user: null,
          });
          // localStorage.removeItem("user");
          if (window.location.pathname == "/order") {
            history("/");
          }
        }
      } else {
        dispatch({
          type: "SET_USER",
          user: null,
        });
        // localStorage.removeItem("user");
        history(path.login);
      }
    });
  }, []);

  return <></>;
}
