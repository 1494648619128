import React, { useState } from "react";
import { FaStar } from "react-icons/fa";

function RatingStars({ raingValue }) {
  // countRequestedProducts
  const [rate, setRate] = useState(0);
  const checkRatingValue = () => {
    let r = raingValue / 5;
  };
  return (
    <div className="rating-star-wraper">
      <FaStar />
      <FaStar />
      <FaStar />
      <FaStar />
      <FaStar />
    </div>
  );
}

export default RatingStars;
