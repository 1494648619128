import React, { useEffect, useState } from "react";
import { useStateValue } from "../../config/StateProvider";
import { Link } from "react-router-dom";
import SpinnerRotate from "../SubComponents/SpinnerRotate";
import { useNavigate, useLocation, useParams } from "react-router";
import {
  getStoreProducts,
  getBySearch,
  getByParentCategory,
  getBySubCategory,
  getStoreProductsCount,
  getBySearchCount,
  getByParentCategoryCount,
  getBySubCategoryCount,
  // getCategories,
  // getDiscountProducts,
  // getDiscountProductsCount,
} from "../../getData/Elastic_search";
import Product from "../Product/Product";
import Pagination from "../Product/Pagination";
import CategoriesList from "../CategoriesList/CategoriesList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBackwardStep,
  faForwardStep,
} from "@fortawesome/free-solid-svg-icons";
import { checkPricingCode, optimizeProductPriceObject } from "../../Funcs";

function ProductList() {
  const { type, myString, subCategory, page } = useParams();
  const [busy, setBusy] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(24);
  const [count, setCount] = useState(0);
  const [{ user, productToAdd, productList }, dispatch] = useStateValue();
  // const [categoryList, setCategoryList] = useState({});
  const [selectValue, setSelectValue] = useState("24");
  const [currentPosts, setCurrentPosts] = useState([]);
  const history = useNavigate();
  const [numberOfPages, setNumberOfPages] = useState(0);
  const location = useLocation();

  useEffect(() => {
    if (
      page > Math.ceil(count / postsPerPage) &&
      Math.ceil(count / postsPerPage) > 0
    ) {
      let myLocation = location.pathname.split(page);
      history(myLocation[0] + "" + Math.ceil(count / postsPerPage));
    }
  }, [location, postsPerPage]);

  async function fetchProducts() {
    let products = [];
    let myCount = 0;
    if (page != null) {
      setCurrentPage(page);
    }
    let from = 0;
    if (page != null) {
      from = (page - 1) * postsPerPage;
    }
    // console.log("toLowerCase", type);
    if (type != null) {
      if (type.toLowerCase() == "search" && subCategory == null) {
        await getBySearch(myString, from, postsPerPage).then((result) => {
          if (result) {
            result.data.hits.hits.map((hit) => {
              const item = optimizeProductPriceObject(hit._source, user);
              products = [...products, item];
            });
            // console.log("by search", products);
          }
        });

        await getBySearchCount(myString).then((result) => {
          if (result.data.count < postsPerPage) {
            setCount(postsPerPage);
            myCount = postsPerPage;
          } else {
            setCount(result.data.count);
            myCount = result.data.count;
          }
        });
      }

      if (type.toLowerCase() == "category" && subCategory == null) {
        await getByParentCategory(myString, from, postsPerPage).then(
          (result) => {
            if (result) {
              result.data.hits.hits.map((hit) => {
                const item = optimizeProductPriceObject(hit._source, user);
                products = [...products, item];
              });
            }
          }
        );

        await getByParentCategoryCount(myString).then((result) => {
          if (result.data.count < postsPerPage) {
            setCount(postsPerPage);
            myCount = postsPerPage;
          } else {
            setCount(result.data.count);
            myCount = result.data.count;
          }
        });
      }

      if (type.toLowerCase() == "category" && subCategory != null) {
        await getBySubCategory(myString, subCategory, from, postsPerPage).then(
          (result) => {
            result.data.hits.hits.map((hit) => {
              const item = optimizeProductPriceObject(hit._source, user);
              products = [...products, item];
            });
          }
        );

        await getBySubCategoryCount(myString, subCategory).then((result) => {
          if (result.data.count < postsPerPage) {
            setCount(postsPerPage);
            myCount = postsPerPage;
          } else {
            setCount(result.data.count);
            myCount = result.data.count;
          }
        });
      }
    } else if (location.pathname.indexOf("/all/discounted") >= 0) {
    } else {
      await getStoreProducts(from, postsPerPage).then((result) => {
        if (result) {
          result.data.hits.hits.map((hit) => {
            const item = optimizeProductPriceObject(hit._source, user);
            products = [...products, item];
          });
        }
      });

      await getStoreProductsCount().then((result) => {
        if (result.data.count < postsPerPage) {
          setCount(postsPerPage);
          myCount = postsPerPage;
        } else {
          setCount(result.data.count);
          myCount = result.data.count;
        }
      });
      // setBusy(false);
    }

    dispatch({
      type: "SET_PRODUCT",
      item: products,
    });
    // console.log("products", products);
    setCurrentPosts(products);
    setNumberOfPages(Math.ceil(myCount / postsPerPage));
    setBusy(false);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    setBusy(true);
    fetchProducts();
  }, [myString, subCategory, page, postsPerPage]);

  const AddToCart = async () => {
    if (user == null) {
      alert("Login to add to your cart");
    } else {
      let i = productToAdd.length - 1;
      productToAdd.map((product, index) => {
        if (index == i) {
          dispatch({
            type: "Add_To_Basket",
            item: product,
            event: "addProduct",
            useruid: user.uid,
          });
        } else {
          dispatch({
            type: "Add_To_Basket",
            item: product,
            event: "addAllProduct",
            useruid: user.uid,
          });
        }
        dispatch({
          type: "REMOVE_PRODUCTS_TO_ADD",
          item: product,
          useruid: user.uid,
        });
      });
    }
  };
  const SelectChange = (e) => {
    setSelectValue(e.target.value);
    setPostsPerPage(parseInt(e.target.value));
  };
  let paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <main className="product-list-page">
      <CategoriesList />
      <section className="page-content" value={selectValue}>
        <div className="pageNumber-wraper">
          <i style={{ color: "#4A474799" }}> Products per page</i>

          <select onChange={SelectChange} className="select-page-number">
            <option value="24">24</option>
            <option value="48">48</option>
            <option value="72">72</option>
            <option value="96">96</option>
            <option value="120">120</option>
          </select>
        </div>
        {busy ? (
          <SpinnerRotate />
        ) : (
          <>
            <ul>
              {currentPosts.map((product, key) => (
                <Product key={key} product={product} />
              ))}
            </ul>

            {productList?.length > 0 ? (
              <>
                <div className="page-container">
                  <Link
                    to={
                      (page != null
                        ? window.location.pathname.split("page/" + page)[0]
                        : window.location.pathname[
                            window.location.pathname.length - 1
                          ] == "/"
                        ? ""
                        : window.location.pathname + "/") +
                      "page/" +
                      (parseInt(currentPage) - 1 <= 0
                        ? 1
                        : parseInt(currentPage) - 1)
                    }
                    className="page-button"
                  >
                    <FontAwesomeIcon icon={faBackwardStep} />
                  </Link>
                  <div className="number-page">
                    <Pagination
                      postsPerPage={postsPerPage}
                      totalPosts={numberOfPages}
                      paginate={paginate}
                      productList={productList}
                    />
                  </div>
                  <Link
                    to={
                      (page != null
                        ? window.location.pathname.split("page/" + page)[0]
                        : window.location.pathname[
                            window.location.pathname.length - 1
                          ] == "/"
                        ? ""
                        : window.location.pathname + "/") +
                      "page/" +
                      (parseInt(currentPage) + 1 >= numberOfPages
                        ? numberOfPages
                        : parseInt(currentPage) + 1)
                    }
                    className="page-button"
                  >
                    <FontAwesomeIcon icon={faForwardStep} />
                  </Link>
                </div>
                {/* <div className="add-to-cart">
                  <span onClick={() => AddToCart()}>Add Items To Cart </span>
                </div> */}
              </>
            ) : (
              <div className="nothing-found-msg">
                <span>no items found :(</span>
              </div>
            )}
          </>
        )}
      </section>
    </main>
  );
}

export default ProductList;
/*<!--<div className="date">{<product.timestamp}</div>*/
